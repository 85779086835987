<template>
  <div class="loader z-50 bg-black/50  rounded-full transition-all duration-1000">
    <h1 class="ai fixed left-[50%] top-[calc(50%-50px)] transform -translate-x-1/2 -translate-y-1/2 select-none">
      AI
    </h1>
    <div class="blobs z-50">
      <span class="blob"></span>
      <span class="blob"></span>
      <span class="blob"></span>
      <span class="blob"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Blobs'
}
</script>

<style lang="scss">
.ai {
  --fontSize: 80px;
  --lineHeight: 80px;
  width: auto;
  height: calc(2 * var(--lineHeight));
  line-height: var(--lineHeight);
  font-size: var(--fontSize);
  text-transform: uppercase;
  text-shadow: 0 0 5px #ffffff, 0 0 20px #000, 0 0 30px #000;
}

:root {
  --aqua: #7FDBFF;
  --blue: #0074D9;
  --navy: #001F3F;
  --teal: #39CCCC;
  --green: #2ECC40;
  --olive: #3D9970;
  --lime: #01FF70;

  --yellow: #FFDC00;
  --orange: #FF851B;
  --red: #FF4136;
  --fuchsia: #F012BE;
  --purple: #B10DC9;
  --maroon: #85144B;

  --white: #FFFFFF;
  --silver: #DDDDDD;
  --gray: #AAAAAA;
  --black: #111111;
}

.loader {
  display: grid;
  grid-template-areas: 'loader';
  position: relative;
}

.blobs {
  display: grid;
  grid-template-areas: 'loader';
  position: relative;
  animation: spin infinite 5s linear;
  grid-area: loader;
}

.blob {
  --border-radius: 115% 140% 145% 110% / 125% 140% 110% 125%;
  --border-width: 40px;

  aspect-ratio: 1;
  display: block;
  grid-area: loader;
  background-size: calc(100% + var(--border-width) * 2);
  background-repeat: no-repeat;
  background-position: center;
  border: var(--border-width) solid transparent;
  border-radius: var(--border-radius, 100%);
  mask-image: linear-gradient(transparent, transparent),
    linear-gradient(black, white);
  mask-clip: padding-box, border-box;
  mask-composite: intersect;
  mix-blend-mode: screen;
  height: 100%;
  filter: blur(10px);

  &:nth-child(1) {
    background-color: var(--blue);
    background-image: linear-gradient(var(--blue), var(--teal), var(--blue));
    rotate: 30deg;
    scale: 1.03;
  }

  &:nth-child(2) {
    background-color: var(--red);
    background-image: linear-gradient(var(--red), var(--orange), var(--red));
    rotate: 60deg;
    scale: 0.95;
  }

  &:nth-child(3) {
    background-color: var(--olive);
    background-image: linear-gradient(var(--olive), var(--lime), var(--olive));
    rotate: 90deg;
    scale: 0.97;
  }

  &:nth-child(4) {
    background-color: var(--purple);
    background-image: linear-gradient(var(--purple), var(--maroon), var(--purple));
    rotate: 120deg;
    scale: 1.02;
  }
}

@keyframes spin {
  from {
    rotate: 0deg;
  }

  to {
    rotate: 360deg;
  }
}
</style>
