<template>
  <section
    class="flex items-center justify-between h-[77px] pl-8 pr-9 bg-mediumMarine fixed z-40 m-auto transition-all duration-300 ease-linear"
    :class="{
      'w-[calc(100vw-77px)]': props.isCollapsed,
      'w-[calc(100vw-216px)]': !props.isCollapsed
    }">

    <!-- Breadcrumb -->
    <div v-if="props.breadcrumb.length !== 0" class="flex items-center">
      <div v-for=" (breadcrumb, idx) in props.breadcrumb" :key="idx" class="flex flex-row items-center cursor-default ">
        <p :key="idx" @click="breadcrumbClickable(idx, breadcrumb)" :class="{
          'sora-XXS-strong text-turquoisePrimary': props.breadcrumb.length - 1 === idx,
          'cursor-pointer sora-XXS text-whiteCustom hover:text-turquoisePrimary': props.breadcrumb.length - 1 !== idx && props.breadcrumb[idx].path !== '#',
          'animate-pulse transition-all duration-300 ease-linear': isAnimating,
          'opacity-0': isAnimating, 'opacity-100': !isAnimating
        }">
          {{ breadcrumb.name }}
        </p>
        <span class="text-whiteCustom sora-XS px-2" v-if="props.breadcrumb.length - 1 !== idx" :class="{
          'animate-pulse transition-all duration-300 ease-linear': isAnimating,
          'opacity-0': isAnimating, 'opacity-100': !isAnimating
        }">&nbsp;·&nbsp;</span>
      </div>
    </div>

    <div v-if="Object.keys(props.button).length !== 0" class="">
      <CustomButton @click="handleButtonClick" :text="props.button.name" size="small" type="primary" />
    </div>

    <div v-if="Object.keys(props.dropdown).length !== 0" class="ml-auto">
      <Dropdown :options="dropdown" :selected="props.selected" position="left" />
    </div>

  </section>
</template>

<script setup>
import { ref, watch } from 'vue'
import router from '@/router'
import Dropdown from '@/components/forms/Dropdown.vue'

const isAnimating = ref(false)

const props = defineProps({
  breadcrumb: { type: Array, default: () => [] },
  button: { type: Object, default: () => ({}) },
  dropdown: { type: Array, default: () => [] },
  selected: String,
  isCollapsed: { type: Boolean, default: false } // if navbar is isCollapsed
})

watch(() => props.breadcrumb, async (newVal, oldVal) => {
  if (newVal !== oldVal) {
    isAnimating.value = true
    setTimeout(() => {
      isAnimating.value = false
    }, 300) // Duración de la animación en milisegundos
  }
})

const breadcrumbClickable = (i, bc) => {
  if (props.breadcrumb.length - 1 !== i) {
    if (bc.path !== '#') {
      router.push({ name: bc.path })
    }
  }
}

const handleButtonClick = () => {
  if (props.button.path) {
    return router.push({ name: props.button.path })
  }

  if (props.button.action) {
    return props.button.action()
  }

}

</script>
