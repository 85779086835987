<template>
  <div class="rounded-full bg-darkMarine flex items-center justify-center" :class="{
    'w-[180px] h-[180px]': size === 'large',
    'w-[40px] h-[40px]': size === 'medium',
    'w-[32px] h-[32px]': size === 'small'
  }">
    <h1 v-if="user.name === 'Pending...'"
      class="h-full w-full flex justify-center items-center sora-M text-whiteCustom/50" :class="{
        'sora-XXL': size === 'large',
        'sora-L': size === 'medium',
        'sora-XS': size === 'small'
      }">
      ? </h1>
    <h1 v-else-if="!user.imgProfile?.small" class="h-full w-full flex justify-center items-center gradient-text" :class="{
      'sora-XXL': size === 'large',
      'sora-L': size === 'medium',
      'sora-XS': size === 'small'
    }">
      {{ formatName() }} </h1>
    <img v-else :src="user.imgProfile?.small" alt="Imagen Usuario"
      class="flex justify-center items-center rounded-full object-cover " :class="{
        'w-[180px] h-[180px]': size === 'large',
        'w-[40px] h-[40px]': size === 'medium',
        'w-[32px] h-[32px]': size === 'small'
      }">
  </div>
</template>
<script setup>
const props = defineProps({
  user: Object,
  size: {
    type: String,
    default: 'large' // 'large', 'medium' or 'small'
  }
})
const formatName = () => {
  if (props.user.name.split(' ').length > 1) {
    return props.user.name.split(' ')[0][0].toUpperCase() + props.user.name.split(' ')[1][0].toUpperCase()
  } else {
    return props.user.name.slice(0, 2).toUpperCase()
  }
}
</script>
