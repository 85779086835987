<template>
  <div class="flex items-center relative" :class="{ 'w-8': isCollapsed }" ref="triggerRef">
    <!-- User Image -->
    <ProfileImage :user="user" :size="isCollapsed ? 'small' : 'medium'" @click="collapsedToggleActionMenu"
      :class="{ 'cursor-pointer': isCollapsed }" />

    <!-- User name & role -->
    <div v-if="!isCollapsed" class="ml-2">
      <p class="sora-XS-strong text-whiteCustom">{{ shortName }}</p>
      <p class="sora-XXS text-whiteCustom/50">{{ user?.role }}</p>
    </div>

    <!-- Action Menu -->
    <Icon v-if="!isCollapsed" icon="action_menu" className="text-whiteCustom cursor-pointer ml-auto" size="w-4 h-4"
      @click="toggleActionMenu" />
    <teleport to="body">
      <div v-if="actionMenu" ref="actionMenuRef" :style="menuStyles"
        class="fixed bg-lightMarine flex flex-col p-2 gap-2 rounded-xs shadow-custom z-50">
        <NavBarItem v-for=" item in actionMenuContent" :key="item.id" :text="item.text" :path="item.path"
          :icon="item.icon" :size="item.size" @click="item.action" />
      </div>
    </teleport>
  </div>
</template>

<script setup>
import { ref, onBeforeUnmount, computed } from 'vue'
import { useStore } from '@/store'
import { useRouter } from 'vue-router'

import NavBarItem from '@/components/app/NavBarItem.vue'
import Icon from '@/components/Icon.vue'
import ProfileImage from '@/components/ProfileImage.vue'

const store = useStore()
const router = useRouter()

const props = defineProps({
  isCollapsed: { type: Boolean, default: false },
  user: { type: Object, required: true }
})

const shortName = computed(() => props.user?.name?.split(' ')[0])

const handleProfileClick = () => {
  router?.currentRoute.value.fullPath.includes('teacher')
    ? router.push('/teacher/profile')
    : router?.currentRoute.value.fullPath.includes('admin')
      ? router.push('/admin/profile')
      : router.push('/app/profile')
  actionMenu.value = false
}

const handleLogoutClick = () => {
  store.logout()
  actionMenu.value = false
}

const actionMenu = ref(false)
const actionMenuRef = ref(null)
const actionMenuContent = ref([
  { text: 'Profile', path: '', icon: 'profile', size: 'w-4 h-4', action: handleProfileClick },
  { text: 'Logout', path: '', icon: 'logout', size: 'w-4 h-4', action: handleLogoutClick }
])
const triggerRef = ref(null)
const menuStyles = ref({
  top: '0px',
  left: '0px'
})

// manage click outside of action menu
const handleClickOutside = (event) => {
  if (actionMenuRef.value && !actionMenuRef.value.contains(event.target)) {
    actionMenu.value = false
    document.removeEventListener('click', handleClickOutside)
  }
}

const positionMenu = () => {
  const triggerRect = triggerRef.value.getBoundingClientRect()
  if (props.isCollapsed) {
    menuStyles.value = {
      top: `${triggerRect.bottom - 100}px`,
      left: `${triggerRect.left + 10}px`
    }
  } else {
    menuStyles.value = {
      top: `${triggerRect.bottom - 110}px`,
      left: `${triggerRect.left + 100}px`
    }
  }
}

const toggleActionMenu = (event) => {
  event.stopPropagation()
  actionMenu.value = !actionMenu.value
  if (actionMenu.value) {
    positionMenu()
    document.addEventListener('click', handleClickOutside)
  } else {
    document.removeEventListener('click', handleClickOutside)
  }
}

// toggle menu action if navbar is collapsed
const collapsedToggleActionMenu = (event) => {
  if (props.isCollapsed) {
    toggleActionMenu(event)
  }
}

// removes all events from clicking outside of action menu
onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script>
