import VMdPreview from '@kangc/v-md-editor/lib/preview'
import '@kangc/v-md-editor/lib/style/preview.css'
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'

// Prism
import Prism from 'prismjs'
// https://github.com/PrismJS/prism/tree/master/themes
import 'prismjs/themes/prism-tomorrow.css' // or whatever theme you like

// https://github.com/PrismJS/prism/tree/master/components
import 'prismjs/components/prism-markup'
import 'prismjs/components/prism-markup-templating'
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-php'
import 'prismjs/components/prism-php-extras'
import 'prismjs/components/prism-sql'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-python'
import 'prismjs/components/prism-css'
import 'prismjs/components/prism-csv'
import 'prismjs/components/prism-excel-formula'
import 'prismjs/components/prism-java'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-mongodb'

// highlight code
import 'prismjs/themes/prism-dark.css'

// V-MD-Preview Plugins

// i18n: english
import enUS from '@kangc/v-md-editor/lib/lang/en-US'

// Copy Code
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index'
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css'

// Highligh Code lines
import createHighlightLinesPlugin from '@kangc/v-md-editor/lib/plugins/highlight-lines/index'
import '@kangc/v-md-editor/lib/plugins/highlight-lines/highlight-lines.css'

// Emojis
import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index'
import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css'

// Mermaid
import createMermaidPlugin from '@kangc/v-md-editor/lib/plugins/mermaid/cdn'
import '@kangc/v-md-editor/lib/plugins/mermaid/mermaid.css'

// Katex
import createKatexPlugin from '@kangc/v-md-editor/lib/plugins/katex/cdn'

// Tip
import createTipPlugin from '@kangc/v-md-editor/lib/plugins/tip/index'
import '@kangc/v-md-editor/lib/plugins/tip/tip.css'

VMdPreview.vMdParser.lang.use('en-US', enUS)
VMdPreview.use(vuepressTheme, { Prism })
VMdPreview.use(createMermaidPlugin())
VMdPreview.use(createCopyCodePlugin())
VMdPreview.use(createHighlightLinesPlugin())
VMdPreview.use(createEmojiPlugin())
VMdPreview.use(createKatexPlugin())
VMdPreview.use(createTipPlugin())

VMdPreview.xss.extend({
  // extend white list
  whiteList: {
    iframe: [
      'frameborder',
      'width',
      'height',
      'src',
      'style',
      'scrolling',
      'title',
      'allowtransparency',
      'allowfullscreen',
      'loading',
      'allow',
      'name'
    ]
  }
})

export default VMdPreview
