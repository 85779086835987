import VMdEditor from '@kangc/v-md-editor/lib/codemirror-editor'
import '@kangc/v-md-editor/lib/style/codemirror-editor.css'
import '@kangc/v-md-editor/lib/theme/style/github.css'

// i18n: english
import enUS from '@kangc/v-md-editor/lib/lang/en-US'

// Resources for the codemirror editor
import Codemirror from 'codemirror'
import '@kangc/v-md-editor/lib/style/codemirror-editor.css'
// mode
import 'codemirror/mode/markdown/markdown'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/mode/css/css'
import 'codemirror/mode/htmlmixed/htmlmixed'
import 'codemirror/mode/vue/vue'
// edit
import 'codemirror/addon/edit/closebrackets'
import 'codemirror/addon/edit/closetag'
import 'codemirror/addon/edit/matchbrackets'
// placeholder
import 'codemirror/addon/display/placeholder'
// active-line
import 'codemirror/addon/selection/active-line'
// scrollbar
import 'codemirror/addon/scroll/simplescrollbars'
import 'codemirror/addon/scroll/simplescrollbars.css'
// style
import 'codemirror/lib/codemirror.css'

import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'

import Prism from 'prismjs'

// Copy Code
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index'
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css'

// Highligh Code lines
import createHighlightLinesPlugin from '@kangc/v-md-editor/lib/plugins/highlight-lines/index'
import '@kangc/v-md-editor/lib/plugins/highlight-lines/highlight-lines.css'

// Emojis
import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index'
import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css'

// Mermaid
import createMermaidPlugin from '@kangc/v-md-editor/lib/plugins/mermaid/cdn'
import '@kangc/v-md-editor/lib/plugins/mermaid/mermaid.css'

// Katex
import createKatexPlugin from '@kangc/v-md-editor/lib/plugins/katex/cdn'

VMdEditor.lang.use('en-US', enUS)
VMdEditor.Codemirror = Codemirror
VMdEditor.use(vuepressTheme, { Prism })
VMdEditor.use(createKatexPlugin())
VMdEditor.use(createCopyCodePlugin())
VMdEditor.use(createHighlightLinesPlugin())
VMdEditor.use(createEmojiPlugin())
VMdEditor.use(createMermaidPlugin())

VMdEditor.xss.extend({
    // extend white list
    whiteList: {
        iframe: [
            'frameborder',
            'width',
            'height',
            'src',
            'style',
            'scrolling',
            'title',
            'allowtransparency',
            'allowfullscreen',
            'loading',
            'allow',
            'name'
        ]
    }
})

export default VMdEditor
