import './assets/css/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { initializeApp } from 'firebase/app'

import VMdPreview from '@/plugins/vmdpreview'
import VMdEditor from '@/plugins/vmdeditor'

import App from './App.vue'
import router from './router'

// Global components - no need to import
import AlertBox from '@/components/AlertBox.vue'
import Modal from '@/components/Modal.vue'
import ModalLoader from '@/components/ModalLoader.vue'
import ModalLoaderAI from '@/components/ModalLoaderAI.vue'
import CustomButton from '@/components/CustomButton.vue'

const pinia = createPinia()

initializeApp({
  apiKey: import.meta.env.VITE_API_KEY,
  authDomain: import.meta.env.VITE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_APP_ID
})

const app = createApp(App)

app.use(router)
app.use(pinia)
app.use(VMdPreview)
app.use(VMdEditor)

app.component('AlertBox', AlertBox)
app.component('Modal', Modal)
app.component('ModalLoader', ModalLoader)
app.component('ModalLoaderAI', ModalLoaderAI)
app.component('CustomButton', CustomButton)
app.mount('#app')
