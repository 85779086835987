<template>
  <section
    class="flex items-center justify-between h-[77px] pl-8 pr-9 bg-mediumMarine fixed z-40  m-auto transition-all duration-300 ease-linear"
    :class="props.isCollapsed ? 'w-[calc(100vw-77px)]' : 'w-[calc(100vw-216px)]'">
    <div v-if="props.breadcrumbs.length !== 0" class="flex items-center">
      <div v-for="(breadcrumb, idx) in props.breadcrumbs" :key="idx" class="flex items-center cursor-default ">
        <p :key="idx" @click="breadcrumbClickable(idx, breadcrumb)" :class="{
          'sora-XXS-strong text-turquoisePrimary': props.breadcrumbs.length - 1 === idx,
          'cursor-pointer sora-XXS text-whiteCustom hover:text-turquoisePrimary': props.breadcrumbs.length - 1 !== idx && props.breadcrumbs[idx].path !== '#',
          'sora-XXS text-whiteCustom': props.breadcrumbs.length - 1 !== idx && props.breadcrumbs[idx].path === '#'
        }">
          {{ breadcrumb.name }}
        </p>
        <span v-if="props.breadcrumbs.length - 1 !== idx" class="text-whiteCustom sora-XS px-2" :class="{
          'animate-pulse transition-all duration-300 ease-linear': isAnimating,
          'opacity-0': isAnimating, 'opacity-100': !isAnimating
        }">&nbsp;·&nbsp;</span>
      </div>
    </div>

    <!-- Right Action Button -->
    <div v-if="Object.keys(props.button).length !== 0">
      <CustomButton @click="handleButtonClick" :text="props.button.name" size="small" />
    </div>
  </section>
</template>


<script setup>
import router from '@/router'
import { ref, watch } from 'vue'

const props = defineProps({
  breadcrumbs: { type: Array, default: () => [] },
  button: { type: Object, default: () => ({}) },
  // button2: { type: Object, default: () => ({}) },
  isCollapsed: { type: Boolean, default: false } // if navbar is isCollapsed
})

const isAnimating = ref(false)

watch(() => props.breadcrumbs, async (newVal, oldVal) => {
  if (newVal !== oldVal) {
    isAnimating.value = true
    setTimeout(() => {
      isAnimating.value = false
    }, 300) // Duración de la animación en milisegundos
  }
})

const breadcrumbClickable = (i, bc) => {
  if (props.breadcrumbs.length - 1 !== i && props.breadcrumbs[i].path !== '#') {
    router.push({ name: bc.path, params: bc.params })
  }
}

const handleButtonClick = () => {
  if (props.button.action) {
    props.button.action()
  } else {
    router.push({ name: props.button.path, params: props.button.params })
  }
}
</script>
