import axios from 'axios'
import { uploadPhoto } from '@/utils/cloudinary'

class ApiService {
  constructor() {
    this.instance = axios.create({
      baseURL: import.meta.env.VITE_API_URL,
      headers: {
        'Content-Type': 'application/json'
        // 'Accept': 'application/json; charset=utf-8'
      }
    })
  }

  // Stores a token in the instance headers & store
  setAuthToken(token) {
    this.instance.defaults.headers.common['Authorization'] = token
  }

  async auth_signup(firebaseToken) {
    const originalBaseURL = this.instance.defaults.baseURL
    try {
      log('Auth Signup')
      // Remove /api from baseURL
      this.instance.defaults.baseURL = originalBaseURL.replace('/api', '')

      // Set Firebase token
      this.instance.defaults.headers.common['Authorization'] = `Bearer ${firebaseToken}`

      const { data } = await this.instance.post('/auth/signup')

      // Set token in instance
      this.setAuthToken(data.token)
      return data
    } catch (error) {
      console.log(error)
    } finally {
      // Restore baseURL
      this.instance.defaults.baseURL = originalBaseURL
    }
  }

  async auth_login(firebaseToken) {
    const originalBaseURL = this.instance.defaults.baseURL
    try {
      log('Auth Login')
      // Remove /api from baseURL
      this.instance.defaults.baseURL = originalBaseURL.replace('/api', '')

      // Set Firebase token
      this.instance.defaults.headers.common['Authorization'] = `Bearer ${firebaseToken}`

      const { data } = await this.instance.post('/auth/login')

      // Set token in instance
      this.setAuthToken(data.token)
      return data
    } catch (error) {
      console.error(error)
    } finally {
      // Restore baseURL
      this.instance.defaults.baseURL = originalBaseURL
    }
  }

  async user_get() {
    log('User Fetch')
    const { data } = await this.instance.get('/users/me')
    return data
  }

  async user_get_cohorts_populated() {
    log('User Fetch')
    const { data } = await this.instance.get('/users/me/cohorts')
    return data
  }

  async user_get_cohorts_student(){
    log('User Fetch')
    const { data } = await this.instance.get('/users/me/user-get-cohorts-student')
    return data
  }

  async user_get_cohorts_populated_without_cohorts_progress() {
    log('User Fetch')
    const { data } = await this.instance.get('/users/me/cohorts-without-cohorts-progress')
    return data
  }

  async user_get_no_cohorts_progress() {
    log('User Fetch')
    const { data } = await this.instance.get('/users/me/no-cohorts-progress')
    return data
  }

  async get_user_cohorts_populated(id) {
    log('User Fetch By Id')
    const { data } = await this.instance.get(`/users/${id}/cohorts`)
    return data
  }

  async get_user(id) {
    log('User Fetch By Id')
    const { data } = await this.instance.get(`/users/${id}`)
    return data
  }

  async get_users() {
    log('All User Fetch')
    const { data } = await this.instance.get('/users/')
    return data
  }

  async get_students_count() {
    log('Students Count Fetch')
    const { data } = await this.instance.get('/users/students-count')
    return data
  }

  async create_user(user) {
    log('User Create')
    const { data } = await this.instance.post('/users/', user)
    return data
  }

  async user_add_skill(skill) {
    log('User Add Skill')
    const { data } = await this.instance.put('/users/me/addSkill', { skill })
    return data
  }

  async user_update_skill(skill) {
    log('User Update Skill')
    const { data } = await this.instance.put('/users/me/updateSkill', { skill })
    return data
  }

  async user_update_profile(userChanges) {
    log('User Update')
    const { data } = await this.instance.put('/users/me', userChanges)
    return data
  }

  async user_update_by_id(id, user) {
    log('User Update by Id', id)
    const { data } = await this.instance.patch(`/users/${id}`, user)
    return data
  }

  async delete_user(userId) {
    log('User Delete')
    const { data } = await this.instance.delete(`/users/${userId}`)
    return data
  }
  async user_update_progress(id, cohortId, skillId, updateData) {
    log('User Update Progress')
    const { data } = await this.instance.put(`/users/updateProgress/${id}`, {
      cohortId,
      skillId,
      updateData
    })
    return data
  }

  async create_cohort(cohort) {
    log('Cohort Create')
    const { data } = await this.instance.post('/cohorts', cohort)
    return data
  }

  async generate_cohort_description(name) {
    log('Cohort Description Create')
    const { data } = await this.instance.post('/cohorts/description', { name })
    return data
  }

  async regenerate_cohort_description(id, name) {
    log('Cohort Description Regenerate')
    const { data } = await this.instance.post(`/cohorts/${id}/regenerate-description`, { name })
    return data
  }

  async delete_cohort(id) {
    log('Cohort Delete')
    const { data } = await this.instance.delete(`/cohorts/${id}`)
    return data
  }

  async get_cohorts() {
    log('Cohorts Fetch')
    const { data } = await this.instance.get('/cohorts')
    return data
  }

  async get_cohorts_unpopulated() {
    log('Cohorts Fetch')
    const { data } = await this.instance.get('/cohorts/unpopulated')
    return data
  }

  async get_cohort(id) {
    log('Cohort Fetch')
    const { data } = await this.instance.get(`/cohorts/${id}`)
    return data
  }

  async get_cohort_student_learn(id) {
    log('Cohort Fetch')
    const { data } = await this.instance.get(`/cohorts/${id}/student-learn`)
    return data
  }

  async get_cohort_unpopulated(id) {
    log('Cohort Fetch')
    const { data } = await this.instance.get(`/cohorts/${id}/unpopulated`)
    return data
  }

  async get_student_cohort(id) {
    log('Cohort Fetch')
    const { data } = await this.instance.get(`/cohorts/${id}/studentCohort`)
    return data
  }

  async update_cohort(id, cohort) {
    log('Cohort Update')
    const { data } = await this.instance.put(`/cohorts/${id}`, cohort)
    return data
  }

  async update_cohort_unpopulated(id, cohort) {
    log('Cohort Update')
    const { data } = await this.instance.put(`/cohorts/${id}/unpopulated`, cohort)
    return data
  }

  async invite_student(id, student) {
    log('Cohort Student Invite')
    const { data } = await this.instance.put(`/cohorts/${id}/students/invite`, { student })
    return data
  }

  async invite_teacher(id, teacher) {
    log('Cohort Teacher Invite')
    const { data } = await this.instance.put(`/cohorts/${id}/teachers/invite`, { teacher })
    return data
  }

  async remove_students(cohort, students) {
    log('Cohort Students Remove')
    const { data } = await this.instance.put(`/cohorts/${cohort._id}/students/remove`, { students })
    return data
  }

  async update_teachers(cohort, teachers) {
    log('Cohort Add Teachers')
    const { data } = await this.instance.put(`/cohorts/${cohort._id}/update-teachers`, { teachers })
    return data
  }

  async update_students(cohort, students) {
    log('Cohort Add Students')
    const { data } = await this.instance.put(`/cohorts/${cohort._id}/update-students`, { students })
    return data
  }

  async send_emails(ids, cohort) {
    log('Welcome to Cohort Emails Sent')
    const { data } = await this.instance.put(`/cohorts/${cohort._id}/send-emails`, { ids })
    return data
  }

  async get_module(id) {
    log('Fetch Module by ID')
    const { data } = await this.instance.get(`/modules/${id}`)
    return data
  }

  async create_module_with_no_cohort(module) {
    log('Module created')
    const { data } = await this.instance.post('/modules', module)
    return data
  }

  async create_module(cohort, module, idx) {
    const { data } = await this.instance.put(`/cohorts/${cohort._id}/create-module`, { idx, module })
    return data
  }

  async update_module(module) {
    const { data } = await this.instance.put(`/modules/${module._id}`, { module })
    return data
  }
  async delete_module(moduleId) {
    const { data } = await this.instance.delete(`/modules/${moduleId}`)
    return data
  }

  async learning_paths_get() {
    const { data } = await this.instance.get('/paths')
    return data
  }

  async get_path_by_id(id) {
    log('Fetch Learning Path by ID')
    const { data } = await this.instance.get(`/paths/${id}`)
    return data
  }

  async get_skills() {
    log('Skills Fetch')
    const { data } = await this.instance.get('/skills')
    return data
  }

  async get_skills_unpopulated() {
    log('Skills Fetch Unpopulated')
    const { data } = await this.instance.get('/skills/unpopulated')
    return data
  }

  async get_active_skills() {
    log('Skills Active Fetch')
    const { data } = await this.instance.get('/skills/active')
    return data
  }
  async get_active_skills_unpopulated() {
    log('Skills Active Fetch Unpopulated')
    const { data } = await this.instance.get('/skills/active-unpopulated')
    return data
  }

  async get_skill(id) {
    log('Skill Fetch')
    const { data } = await this.instance.get(`/skills/${id}`)
    return data
  }

  async skill_name_format(name) {
    log('Skill Name Format')
    const { data } = await this.instance.post('/skills/name-format', { name })
    return data
  }

  async create_skill(skill) {
    log('Skill Create')
    const { data } = await this.instance.post('/skills', skill)
    return data
  }

  async skill_generate(name) {
    log('Skill Generate')
    const { data } = await this.instance.post('/skills/generate', { name })
    return data
  }
  async skill_generate_description(name) {
    log('Skill Description Generate')
    const { data } = await this.instance.post('/skills/generate/description', { name })
    return data
  }

  async skill_generate_goal(skillName, skillGoals, skillDescription, goal) {
    log('Skill Goal Generate')
    const { data } = await this.instance.post('/skills/generate/goal', {
      skill: {
        name: skillName,
        goals: skillGoals,
        description: skillDescription
      },
      goal
    })
    return data
  }

  async skill_generate_goals(name, description) {
    log('Skill Goals Create')
    const { data } = await this.instance.post('/skills/generate/goals', { name, description })
    return data
  }

  async summarize_goals(goals, name, description) {
    log('Skill Summarize Goals')
    const { data } = await this.instance.post('/skills/summarize/goals', { goals, name, description })
    return data
  }

  async expand_goals(goals, name, description) {
    log('Skill Expand Goals')
    const { data } = await this.instance.post('/skills/expand/goals', { goals, name, description })
    return data
  }

  async summarize_topics(topics, goals, name, description) {
    log('Skill Summarize Topics')
    const { data } = await this.instance.post('/skills/summarize/topics', { topics, goals, name, description })
    return data
  }

  async expand_topics(topics, goals, name, description) {
    log('Skill Expand Topics')
    const { data } = await this.instance.post('/skills/expand/topics', { topics, goals, name, description })
    return data
  }

  async chat_assistant(message) {
    log('chatting')
    const { data } = await this.instance.post('/skills/assistant', { message })
    return data
  }

  async regenerate_skill_description(skill) {
    log('Skill Description Regenerate')
    try {
      const { data } = await this.instance.post('/skills/regenerate/description', skill)
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async regenerate_skill_goals(skill) {
    log('Skill Goals Regenerate')
    try {
      const { data } = await this.instance.post('/skills/regenerate/goals', skill)
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async update_skill(id, skill) {
    log('Skill Update')
    const { data } = await this.instance.put(`/skills/${id}`, skill)
    return data
  }

  async delete_skill(id) {
    log('Skill Delete')
    const { data } = await this.instance.delete(`/skills/${id}`)
    return data
  }
  async create_topics(id) {
    log('Skill Topics Creation')
    const { data } = await this.instance.post(`/skills/${id}/topics`)
    return data
  }

  async topics_create_many(id, topics) {
    log('Skill Topics Creation')
    const { data } = await this.instance.post(`/skills/${id}/topics/create-many`, { topics })
    return data
  }

  async generate_topics(skill, numTopics) {
    log('Skill Topics Generation')
    const { data } = await this.instance.post('/skills/generate/topics', { skill, numTopics })
    return data
  }

  async generate_topic(skillName, skillTopics, skillDescription, goal) {
    log('Skill Topic Generation')
    const { data } = await this.instance.post('/skills/generate/topic', {
      skill: {
        name: skillName,
        topics: skillTopics,
        description: skillDescription
      },
      goal
    })
    return data
  }

  async create_topic(topic) {
    log('Topic Create')
    const { data } = await this.instance.post('/topics', topic)
    return data
  }

  async update_topic(id, topic) {
    log('Topic Update')
    const { data } = await this.instance.put(`/topics/${id}`, topic)
    return data
  }

  async delete_topic(id) {
    log('Topic Delete')
    const { data } = await this.instance.delete(`/topics/${id}`)
    return data
  }

  async generate_topic_info(topicName) {
    log('Topic Info Generation')
    const { data } = await this.instance.post('/topics/generate-info', { topicName })
    return data
  }

  async generate_topic_goal(topicId, goalsCopy, input) {
    log('Topic Goal Generation')
    const { data } = await this.instance.post(`/topics/${topicId}/generate-goal`, { goalsCopy, input })
    return data
  }

  async generate_topic_description(topicId) {
    log('Topic Description Generation')
    const { data } = await this.instance.post(`/topics/${topicId}/generate-description`)
    return data
  }

  async get_lesson(id) {
    log('Lesson Fetch')
    const { data } = await this.instance.get(`/lessons/${id}`)
    return data
  }

  async get_lessons_count() {
    log('Lessons Fetch')
    const { data } = await this.instance.get('/lessons/count')

    return data
  }

  async get_lessons_open_quizzes_count(lessonsIds) {
    log('Open quizzes count')
    const { data } = await this.instance.post('/lessons/open-quizz-count', { lessonsIds })
    return data
  }

  async create_lesson(lesson, topic_id) {
    log('Lesson Create')
    const { data } = await this.instance.post('/lessons', { lesson, topic_id })
    return data
  }

  async generate_lesson_goal(lessonId, goalsCopy, input) {
    log('Lesson Goal Generation')
    const { data } = await this.instance.post(`/lessons/${lessonId}/generate-goal`, { goalsCopy, input })
    return data
  }

  async update_lesson(id, lesson) {
    log('Lesson Update')
    const { data } = await this.instance.put(`/lessons/${id}`, lesson)
    return data
  }

  async delete_lesson(id) {
    log('Lesson Deleted')
    const { data } = await this.instance.delete(`/lessons/${id}`)
    return data
  }

  async create_note(note) {
    log('Note Create')
    const { data } = await this.instance.post('/notes', note)
    return data
  }

  async delete_note(id) {
    log('Note Delete')
    const { data } = await this.instance.delete(`/notes/${id}`)
    return data
  }

  async generate_lesson(id, lessons, newLesson, genOption) {
    log('Topic Lesson Generation')
    const { data } = await this.instance.post(`/topics/${id}/generate-lesson`, { lessons, newLesson, genOption })
    return data
  }

  async generate_lessons(id) {
    log('Topic Lessons Generation')
    const { data } = await this.instance.post(`/topics/${id}/generate-lessons`)
    return data
  }

  async generate_lessons_oneshot(skillId) {
    log('Topic Lessons Generation')
    const { data } = await this.instance.post(`/topics/${skillId}/generate-lessons-oneshot`)
    return data
  }

  async generate_content(id, skillName, tone) {
    log('Lesson Content Generation')
    const { data } = await this.instance.post(`/lessons/${id}/generate-content`, { tone, skillName })
    return data
  }

  async generate_content_oneshot(skillId) {
    log('Lesson Content Generation')
    const { data } = await this.instance.post(`/lessons/${skillId}/generate-content-oneshot`)
    return data
  }

  async generate_tf_block(id, content) {
    log('Lesson TF Block Generation')
    const { data } = await this.instance.post(`/lessons/${id}/generate-tf-block`, { content })
    return data
  }

  async generate_multi_block(id, content) {
    log('Lesson MultiOption Block Generation')
    const { data } = await this.instance.post(`/lessons/${id}/generate-multi-block`, { content })
    return data
  }

  async generate_text_block(skill, lesson, input) {
    log('Lesson Text Block Generation')
    const { data } = await this.instance.post(`/lessons/${lesson}/generate-text-block`, { skill, input })
    return data
  }

  async generate_justify_block(id, content, skillName) {
    log('Lesson Justify Block Generation')
    const { data } = await this.instance.post(`/lessons/${id}/generate-justify-block`, { content, skillName })
    return data
  }

  async verify_justify_answer(quizId, answer) {
    log('Verifying Justify Answer')
    const { data } = await this.instance.post(`/lessons/${quizId}/verify-justify-answer`, { answer })
    return data
  }

  async modify_content(content, input) {
    log('Modifying Content')
    const { data } = await this.instance.put('/blocks/modify-content', { content, input })
    return data
  }

  async split_content(block) {
    log('Splitting Content')
    const { data } = await this.instance.post('/blocks/split-content', block)
    return data
  }

  async add_block(lesson_id, newBlock, position) {
    log(`Adding ${newBlock.__t} Block to lesson `, lesson_id)
    try {
      const { data } = await this.instance.post(`/lessons/${lesson_id}/block`,
        { block: newBlock, position })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  async update_block(newBlock) {
    log(`Updating ${newBlock.type} Block `, newBlock._id)
    try {
      const { data } = await this.instance.put(`/blocks/${newBlock._id}`, newBlock)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  async remove_block(blockId, lessonId) {
    log('Block deleted')
    try {
      const { data } = await this.instance.delete(`/lessons/${lessonId}/block/${blockId}`)
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async get_categories() {
    log('Categories Fetch')
    try {
      const { data } = await this.instance.get('/categories')
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async get_subscriptions_plans() {
    log('Subscription Plans Fetch')
    const { data } = await this.instance.get('/stripe/products')
    return data
  }

  async user_add_path(body) {
    try {
      const { data } = await this.instance.put('/users/me/addPath', body)
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async generate_path(path) {
    try {
      const { data } = await this.instance.post('/paths/generate', path)
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async regenerate_path_description(path) {
    try {
      const { data } = await this.instance.post('/paths/regenerate/description', path)
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async regenerate_path_goals(path) {
    try {
      const { data } = await this.instance.post('/paths/regenerate/goals', path)
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async update_path(id, path) {
    try {
      const { data } = await this.instance.put(`/paths/${id}`, path)
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async create_custom_path(path) {
    try {
      const { data } = await this.instance.post('/paths/custom', path)
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async update_custom_path(id, path) {
    try {
      const { data } = await this.instance.put(`/paths/custom/${id}`, path)
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async delete_custom_path(id) {
    try {
      const { data } = await this.instance.delete(`/paths/custom/${id}`)
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async get_one_skill(id) {
    try {
      const { data } = await this.instance.get(`/skills/${id}`)
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async generate_slides(id) {
    try {
      const { data } = await this.instance.post(`/lessons/${id}/generate-slides`)
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async update_slides(id, presentation) {
    try {
      const { data } = await this.instance.put(`/lessons/${id}/update-slides`, { presentation })
      return data
    } catch (error) {
      console.error(error)
    }
  }

  // CLOUDINARY UPLOADER
  async upload_image(file, folder) {
    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('upload_preset', 'o482jwtv')
      formData.append('folder', folder)
      const response = await fetch(
        'https://api.cloudinary.com/v1_1/dbjlaop5w/auto/upload', {
        method: 'POST',
        body: formData
      }
      )
      return response.json()
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async user_update_photo(id, file, folder) {
    const urls = await uploadPhoto(file, folder)
    const response = await this.instance.patch(`/users/${id}`, { imgProfile: urls })
    return response.data
  }

  async profile_update_photo(id, file, folder) {
    const urls = await uploadPhoto(file, folder)
    const response = await this.instance.put('/users/me', { imgProfile: urls })
    return response.data
  }

  async get_event_logs() {
    log('Event-logs loaded')
    try {
      const { data } = await this.instance.get('/event-log')
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async get_admin_event_logs() {
    log('Event-logs of admin loaded')
    try {
      const { data } = await this.instance.get('/event-log/admin')
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async get_event_logs_by_user(userId) {
    log('Event-logs of user loaded')
    try {
      const { data } = await this.instance.get(`/event-log/${userId}`)
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async create_event_log(eventLog) {
    log('Event-Log created')
    try {
      const { data } = await this.instance.post('/event-log', eventLog)
      return data
    } catch (error) {
      console.error(error)
    }

  }

  async get_student_event_logs(userId) {
    log('Event-logs of student loaded')
    try {
      const { data } = await this.instance.get(`/event-log/student/${userId}`)
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async chatbot(chat) {
    log('Chatbot')
    try {
      const { data } = await this.instance.post('/chatbot', chat)
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async create_thread(userId, topicId) {
    log('Thread created')
    try {
      const { data } = await this.instance.post('/users/create-thread', { userId, topicId })
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async send_new_message(threadId, assistantId, message) {
    log('Message sent')
    try {
      const { data } = await this.instance.post('/users/send-message', { threadId, assistantId, message })
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async get_thread_messages(threadId) {
    log('Thread messages loaded')
    try {
      const { data } = await this.instance.get(`/chat-message/${threadId}`)
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async update_message(id, messageChanges) {
    log('Message updated')
    try {
      const { data } = await this.instance.put(`/chat-message/${id}`, messageChanges)
      return data
    } catch (error) {
      console.error(error)
    }
  }
}
const apiService = new ApiService()

export default apiService

const log = (...msgs) => {
  const formattedMsgs = msgs.map((msg) => {
    if (msg && typeof msg === 'object' && 'value' in msg) {
      return msg.value
    }
    return typeof msg === 'object' ? 'Unserializable object' : msg
  })
  console.info(`[  API  ]  ${formattedMsgs.join(' ')}`)
}
