import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from '@/store'

// LAYOUTS
import HomeLayout from '@/layouts/HomeLayout.vue'
import AppLayout from '@/layouts/AppLayout.vue'
import AdminLayout from '@/layouts/AdminLayout.vue'
import TeacherLayout from '@/layouts/TeacherLayout.vue'

const routes = [
  {
    path: '/',
    component: HomeLayout,
    children: [
      { path: '/', meta: { checkAuth: true }, name: 'home', component: () => import('@/views/Home.vue') },
      { path: '/welcome', meta: { requiresAuth: true, requiresFromHome: true }, name: 'welcome', component: () => import('@/views/Welcome.vue') }
    ]
  },
  {
    path: '/error',
    component: { template: '<h1>error test</h1>' },
    beforeEnter: () => {
      throw new Error('This is a test error')
    }
  },
  {
    path: '/app',
    component: AppLayout,
    meta: { requiresAuth: true, requiresIsActive: true, requiresStudent: true },
    children: [
      { path: 'dashboard', name: 'dashboard', component: () => import('@/views/app/Dashboard.vue'), meta: { cohorts: true } },
      { path: 'profile', name: 'app-user-profile', component: () => import('@/views/app/UserProfile.vue') },
      {
        path: 'cohorts',
        children: [
          { path: '', name: 'cohorts', component: () => import('@/views/app/cohorts/Cohorts.vue'), meta: { cohorts: true } },
          { path: ':id', name: 'view-cohort', component: () => import('@/views/app/cohorts/Cohort.vue'), meta: { cohorts: true } },
          { path: ':id/:skillId/stats', name: 'stats-cohort', component: () => import('@/views/app/cohorts/SkillStats.vue'), meta: { cohorts: true } },
          { path: ':cohortId/:skillId/:mode/welcome', name: 'student-lesson-welcome', component: () => import('@/views/app/lesson-view/LessonView.vue'), meta: { cohorts: true } },
          { path: ':cohortId/:skillId/:mode/topic/:topicId/lesson/:lessonId', name: 'student-lesson-view', component: () => import('@/views/app/lesson-view/LessonView.vue'), meta: { cohorts: true } }
        ]
      },
      {
        path: 'learning-paths',
        children: [
          { path: '', name: 'learning-paths', component: () => import('@/views/app/learning-paths/LearningPaths.vue') },
          { path: 'add-path', name: 'add-learning-path', component: () => import('@/views/app/learning-paths/AddLearningPath.vue') },
          { path: 'add-path-custom-1', name: 'add-path-custom-1', component: () => import('@/views/app/learning-paths/AddLearningPathCustom.vue') },
          { path: 'add-path-custom-2', name: 'add-path-custom-2', component: () => import('@/views/app/learning-paths/AddLearningPathCustomStep2.vue') },
          { path: 'add-path-custom-3', name: 'add-path-custom-3', component: () => import('@/views/app/learning-paths/AddLearningPathCustomStep3.vue') },
          { path: ':pathId', name: 'learning-path', component: () => import('@/views/app/learning-paths/LearningPath.vue') }
        ]
      },
      {
        path: 'skills',
        children: [
          { path: '', name: 'skills', component: () => import('@/views/app/skills/MySkills.vue') },
          { path: ':id', name: 'skill-home', component: () => import('@/views/app/skills/SkillHome.vue') }
        ]
      }
    ]
  },
  {
    path: '/study',
    meta: { requiresAuth: true, requiresIsActive: true },
    //:mode -> ValidModes = ['StudentView', 'TeacherView', 'learn'],
    children: [
      { path: ':skillId/:mode/welcome', name: 'lesson-view-welcome', component: () => import('@/views/app/lesson-view/LessonView.vue') },
      { path: ':skillId/:mode/topic/:topicId/lesson/:lessonId', name: 'lesson-view', component: () => import('@/views/app/lesson-view/LessonView.vue') },
      { path: ':skillId/congrats', name: 'congrats-view', component: () => import('@/views/app/lesson-view/CongratsView.vue') },
      { path: ':cohortId/:skillId/:mode/welcome', name: 'lesson-teach-welcome', component: () => import('@/views/app/lesson-view/LessonView.vue') },
      { path: ':cohortId/:skillId/:mode/topic/:topicId/lesson/:lessonId', name: 'lesson-teach', component: () => import('@/views/app/lesson-view/LessonView.vue') },
      { path: ':cohortId/:skillId/:mode/welcome', name: 'lesson-learning-welcome', component: () => import('@/views/app/lesson-view/LessonView.vue') },
      { path: ':cohortId/:skillId/:mode/topic/:topicId/lesson/:lessonId', name: 'lesson-learning', component: () => import('@/views/app/lesson-view/LessonView.vue') }
    ]
  },
  {
    path: '/teacher',
    component: TeacherLayout,
    meta: { requiresAuth: true, requiresIsActive: true, requiresTeacher: true },
    children: [
      { path: 'dashboard', name: 'teacher-dashboard', component: () => import('@/views/teacher/TeacherDashboard.vue'), meta: { cohorts: true } },
      {
        path: 'cohorts',
        children: [
          { path: '', name: 'teacher-cohorts', component: () => import('@/views/teacher/Cohorts.vue'), meta: { cohorts: true } },
          { path: ':id', name: 'teacher-view-cohort', component: () => import('@/views/teacher/ViewCohort.vue') },
          { path: ':cohortId/:skillId/:mode/welcome', name: 'teacher-teach-welcome', component: () => import('@/views/app/lesson-view/LessonView.vue') },
          { path: ':cohortId/:skillId/:mode/topic/:topicId/lesson/:lessonId', name: 'teacher-teach-view', component: () => import('@/views/app/lesson-view/LessonView.vue') }
        ]
      },
      {
        path: 'skills',
        children: [
          { path: '', name: 'teacher-skills', component: () => import('@/views/teacher/TeacherSkills.vue') },
          { path: ':id', name: 'teacher-view-skill', component: () => import('@/views/teacher/SkillHome.vue') },
          { path: ':skillId/:mode/welcome', name: 'teacher-lesson-welcome', component: () => import('@/views/app/lesson-view/LessonView.vue') },
          { path: ':skillId/:mode/topic/:topicId/lesson/:lessonId', name: 'teacher-lesson-view', component: () => import('@/views/app/lesson-view/LessonView.vue') }
        ]
      },
      {
        path: 'profile',
        children: [
          { path: '', name: 'teacher-profile', component: () => import('@/views/app/UserProfile.vue') },
          { path: 'edit', name: 'teacher-edit-profile', component: () => import('@/views/teacher/EditMyProfile.vue') }
        ]
      },
      {
        path: 'users',
        children: [
          { path: '', name: 'teacher-users', component: () => import('@/views/teacher/Users.vue'), meta: { cohorts: true } },
          { path: ':user_id', name: 'teacher-user-profile', component: () => import('@/views/teacher/UserProfile.vue') }
        ]
      }
    ]
  },
  {
    path: '/admin',
    component: AdminLayout,
    meta: { requiresAuth: true, requiresIsActive: true, requiresTeacher: true },
    children: [
      { path: 'dashboard', name: 'admin-dashboard', component: () => import('@/views/admin/AdminDashboard.vue') },
      { path: 'profile', name: 'user-profile', component: () => import('@/views/app/UserProfile.vue') },
      {
        path: 'cohorts',
        children: [
          { path: '', name: 'admin-cohorts', component: () => import('@/views/admin/Cohorts.vue') },
          { path: 'create', name: 'admin-create-cohort', component: () => import('@/views/admin/CreateCohort.vue') },
          { path: 'create-2', name: 'admin-create-cohort-2', component: () => import('@/views/admin/CreateCohortStep2.vue') },
          { path: 'create-3', name: 'admin-create-cohort-3', component: () => import('@/views/admin/CreateCohortStep3.vue') },
          { path: 'create-4', name: 'admin-create-cohort-4', component: () => import('@/views/admin/CreateCohortStep4.vue') },
          { path: ':id', name: 'admin-view-cohort', component: () => import('@/views/admin/ViewCohort.vue') },
          { path: ':id/edit', name: 'admin-edit-cohort', component: () => import('@/views/admin/EditCohort.vue') },
          { path: ':id/create-module', name: 'admin-create-module', component: () => import('@/views/admin/CreateModule.vue') },
          { path: ':id/module/add-skills', name: 'admin-create-module-add-skills', component: () => import('@/views/admin/CreateModuleAddSkills.vue') },
          { path: ':cohortId/module/:moduleId', name: 'admin-edit-module', component: () => import('@/views/admin/EditModule.vue') },
          { path: ':cohortId/module/:moduleId/add-skills', name: 'admin-edit-module-add-skills', component: () => import('@/views/admin/EditModuleAddSkills.vue') }
        ]
      },
      {
        path: 'paths',
        children: [
          { path: '', name: 'admin-learning-paths', component: () => import('@/views/admin/LearningPaths.vue') },
          { path: 'create', name: 'admin-create-path', component: () => import('@/views/admin/CreateLearningPath.vue') },
          { path: ':id', name: 'admin-edit-path', component: () => import('@/views/admin/EditLearningPath.vue') }
        ]
      },
      {
        path: 'skills',
        children: [
          { path: '', name: 'admin-skills', component: () => import('@/views/admin/Skills.vue') },
          { path: 'create', name: 'admin-add-skill', component: () => import('@/views/admin/CreateSkill.vue') },
          { path: 'create-2-AI', name: 'admin-add-skill-2-ai', component: () => import('@/views/admin/CreateSkillStep2-AI.vue') },
          { path: 'create-2-Scratch', name: 'admin-add-skill-2-scratch', component: () => import('@/views/admin/CreateSkillStep2-Scratch.vue') },
          { path: 'create-3', name: 'admin-add-skill-3', component: () => import('@/views/admin/CreateSkillStep3.vue') },
          { path: ':id', name: 'admin-view-skill', component: () => import('@/views/admin/ViewSkill.vue') },
          { path: ':id/edit', name: 'admin-edit-skill', component: () => import('@/views/admin/EditSkill.vue') },
          {
            path: ':id/topics',
            name: 'admin-edit-skill-content',
            component: () => import('@/views/admin/EditSkillContent.vue'),
            children: [
              { path: '', name: 'admin-topic-empty', component: () => import('@/views/admin/TopicEmpty.vue') },
              { path: ':topic_id', name: 'admin-topic-edit', component: () => import('@/components/admin/FormEditTopic.vue') },
              { path: ':topic_id/lessons/:lesson_id/edit', name: 'admin-lesson-edit', component: () => import('@/components/admin/lessonEditor/LessonEditor.vue') },
              { path: ':topic_id/lessons/:lesson_id', name: 'admin-lesson-view', component: () => import('@/components/admin/lessonEditor/LessonViewer.vue') }
            ]
          }
        ]
      },
      {
        path: 'users',
        children: [
          { path: '', name: 'admin-users', component: () => import('@/views/admin/Users.vue') },
          { path: 'create', name: 'admin-create-user', component: () => import('@/views/admin/CreateUser.vue') },
          { path: ':user_id', name: 'admin-user-profile', component: () => import('@/views/admin/UserProfile.vue') },
          { path: ':user_id/settings', name: 'admin-edit-user', component: () => import('@/views/admin/EditUser.vue') },
          { path: ':user_id/skill/:skill_id', name: 'admin-user-skill', component: () => import('@/views/admin/AdminUserSkill.vue') }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  // scrool to top after each new route
  scrollBehavior() {
    return { top: 0 }
  },
  routes
})

router.beforeEach(async (to, from, next) => {
  const store = useStore()

  // Redirects to dashboard if user goes to login and it has token
  if (to.meta.checkAuth) {
    if (store.token) {
      try {
        return next({ name: 'dashboard' })
      } catch (error) {
        return next({ name: 'home' })
      }
    }
  }
  // Redirects user to home if not logged in
  if (to.meta.requiresAuth) {
    if (!store.token) {
      log('BeforeEach | User not logged in - redirects home')
      return next({ name: 'home' })
    }

    // Clear header state (Breadcrumb, buttons...)
    if (store.headerBreadcrumb) { store.headerBreadcrumb = [] }
    if (store.headerButton) { store.headerButton = {} }
    if (store.headerButton2) { store.headerButton2 = {} }
    if (store.headerDropdown) { store.headerDropdown = [] }
    if (store.headerSelected) { store.headerSelected = '' }

    try {
      log('BeforeEach | Fetching user from router')
      // Fetch user from backend
      // userFetch now returns the object and updates here due to problems
      // with the reactivity of the store not updating the user object in time
      let response
      if (to.meta.cohorts) {
        response = await store.userFetch({ cohorts: true })
      } else if (to.meta.cohortsWithoutProgress) {
        response = await store.userFetchCohortsPopulatedWithoutCohortsProgress()
      } else if (to.meta.populated) {
        response = await store.userFetch()
      } else {
        response = await store.userFetchWithoutCohortsProgress()
      }
      store.user = response
    } catch (error) {
      store.add_alert({ title: 'Something went wrong!', message: 'Please login again', type: 'error' })
      console.error(error)
      // resets token in case it has expired
      store.token = ''
      localStorage.removeItem('token')
      next({ name: 'home' })
    }

    if (to.meta.requiresFromHome) {
      if (store.token) {
        if (store.user.isActive) {
          if (store.user.role === 'Student') {
            return next({ name: 'dashboard' })
          } else {
            return next({ name: 'teacher-dashboard' })
          }
        }
      }
    }

    if (to.meta.requiresStudent && store.user.role !== 'Student') {
      log('BeforeEach | User is not student - redirects teacher/dashboard')
      return next({ name: 'teacher-dashboard' })
    }

    if (to.meta.requiresTeacher && store.user.role === 'Student') {
      log('BeforeEach | User is not admin/teacher - redirects dashboard')
      return next({ name: 'dashboard' })
    }

    if (to.meta.requiresAdmin && !store.user.role === 'Admin') {
      log('BeforeEach | User is not admin - redirects dashboard')
      if (store.user.role === 'Teacher') return next({ name: 'teacher-dashboard' })
      if (store.user.role === 'Student') return next({ name: 'dashboard' })
    }

    if (to.meta.requiresIsActive && !store.user.isActive) {
      log('BeforeEach | User is not active - redirects settings')
      return next({ name: 'welcome' })
    }
  }

  // normal routes
  next()
})

export default router

const log = (...msgs) => {
  const formattedMsgs = msgs.map((msg) => {
    if (msg && typeof msg === 'object' && 'value' in msg) {
      return msg.value
    }
    return typeof msg === 'object' ? 'Unserializable object' : msg
  })
  console.info(`[ Router ]  ${formattedMsgs.join(' ')}`)
}
