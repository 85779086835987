<template>
  <Suspense>
    <template #default>
      <div class="flex">
        <NavBar :menu="menu" :panelOptions="panelOptions" :isCollapsed="isCollapsed" :user="user"
          @toggleCollapse="toggleCollapse" isDark />

        <div class="w-full h-screen " :class="{ 'ml-[216px]': !isCollapsed, 'ml-[77px]': isCollapsed }">
          <AdminHeader :breadcrumb="headerBreadcrumb" :button="headerButton" :isCollapsed="isCollapsed"
            :dropdown="headerDropdown" :selected="headerSelected" />

          <div class="pt-[77px] h-screen">
            <RouterView :isCollapsed="isCollapsed" />
          </div>

          <!-- Global notification live region -->
          <div aria-live="assertive"
            class="pointer-events-none fixed top-0 left-0 right-0 flex justify-end items-center z-50 pt-8">
            <!-- To add notifications, call this from anywhere in the app:
              store.add_alert({ type, title, message })
            -->
            <div class="pr-8 flex flex-col gap-4">
              <AlertBox v-for="alert in alerts" :key="alert.id" :id="alert.id" :type="alert.type" :title="alert.title"
                :message="alert.message" @close="store.remove_alert" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #fallback>
      <ModalLoader />
    </template>
  </Suspense>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useStore } from '@/store'
import { useRouter } from 'vue-router'

import NavBar from '@/components/NavBar.vue'
import AdminHeader from '@/components/admin/AdminHeader.vue'
import ModalLoader from '../components/ModalLoader.vue'

const router = useRouter()

const store = useStore()
const { user, alerts, headerBreadcrumb, headerButton, headerDropdown, headerSelected } = storeToRefs(store)

const menu = [
  { text: 'Dashboard', path: '/admin/dashboard', icon: 'dashboard' },
  { text: 'Cohorts', path: '/admin/cohorts', icon: 'cohorts' },
  { text: 'Skills', path: '/admin/skills', icon: 'skills' },
  { text: 'Users', path: '/admin/users', icon: 'users' }
]

const panelOptions = [
  { name: 'Admin', action: () => router.push('/admin/dashboard'), disabled: false },
  { name: 'Teacher', action: () => router.push('/teacher/dashboard'), disabled: false }
]

//***********************************************************
// COLLAPSING LOGIC
//***********************************************************
const savedStateNavBar = localStorage.getItem('savedStateNavBar')
const isCollapsed = ref(savedStateNavBar ? JSON.parse(savedStateNavBar) : false)

const toggleCollapse = () => {
  isCollapsed.value = !isCollapsed.value
  localStorage.setItem('savedStateNavBar', JSON.stringify(isCollapsed.value))
}

const handleResize = () => {
  if (window.innerWidth <= 1036 && !isCollapsed.value) {
    isCollapsed.value = !isCollapsed.value
  }
}

onMounted(async () => {
  window.addEventListener('resize', handleResize)
  handleResize()
})
</script>
