<template>
  <button
    class="flex justify-center items-center text-center select-none transition-all duration-300 ease-in whitespace-nowrap"
    :class="[sizeClasses, typeClasses]" :disabled="props.disabled" @click.prevent="handleClick">
    <!-- Icon is only available with ai or order -->
    <Icon v-if="props.showIcon" icon="ai" class="mr-[10px] text-center" :class="iconSizeClasses" />
    <Icon v-if="props.type === 'iconTertiary'" :icon="prependIcon" class="w-4 h-4 my-auto mr-2 text-center" />
    <Icon v-if="props.type === 'statsAction'" icon="stadistic" class="w-[14px] h-[14px] my-auto mr-2 text-center" />
    {{ props.text }}
  </button>
</template>

<script setup>
import { computed } from 'vue'
import Icon from '@/components/Icon.vue'

const props = defineProps({
  text: String,
  showIcon: { type: Boolean, default: false },
  size: { type: String, default: 'big' }, // big, small, extraSmall
  type: { type: String, default: 'primary' }, // primary, secondary, tertiary, iconTertiary, sensitive, edit, aiSelector
  disabled: { type: Boolean, default: false },
  prependIcon: { type: String, default: 'order' }
})

const getSizeClasses = (size) => {
  const sizeClassMap = {
    big: 'rounded-m py-4 px-6 SPACE-L',
    small: 'rounded-xs py-[10px] px-4 SPACE-M',
    extraSmall: 'rounded-xs p-2 SPACE-XXS'
  }
  return sizeClassMap[size] || ''
}

const getTypeClasses = (type, disabled) => {
  const typeClassMap = {
    primary: disabled
      ? 'bg-[#564df7cc] text-whiteCustom/30 cursor-not-allowed'
      : 'bg-violetPrimary text-whiteCustom primary-hover primary-active cursor-pointer',
    secondary: disabled
      ? 'bg-[#33A799] text-darkMarine/80 cursor-not-allowed'
      : 'bg-turquoisePrimary text-darkMarine secondary-hover secondary-active cursor-pointer',
    tertiary: disabled
      ? 'bg-transparent border border-whiteCustom text-whiteCustom opacity-30 cursor-not-allowed'
      : 'bg-transparent border border-whiteCustom text-whiteCustom hover:bg-[#ffffff0d] active:opacity-80 cursor-pointer',
    iconTertiary: disabled
      ? 'bg-transparent border border-whiteCustom text-whiteCustom opacity-30 cursor-not-allowed'
      : 'bg-transparent border border-whiteCustom text-whiteCustom hover:bg-[#ffffff0d] active:opacity-80 cursor-pointer',
    sensitive: disabled
      ? 'bg-lightMarine/80 text-lightViolet/50 cursor-not-allowed'
      : 'bg-lightMarine text-lightViolet action-hover action-active cursor-pointer',
    edit: disabled
      ? 'bg-lightMarine/80 text-mediumGray/50 cursor-not-allowed'
      : 'bg-lightMarine text-mediumGray action-hover action-active hover:text-whiteCustom cursor-pointer',
    aiSelector: disabled
      ? 'bg-mediumMarine/80 text-turquoisePrimary/50 border-turquoiseGradient/60 cursor-not-allowed'
      : 'bg-mediumMarine text-turquoisePrimary border-turquoiseGradient cursor-pointer',
    statsAction: disabled
      ? 'bg-lightMarine/80 border border-mediumGray text-whiteCustom opacity-30 cursor-not-allowed'
      : 'bg-lightMarine text-mediumGray border border-mediumGray hover:border-whiteCustom hover:text-whiteCustom cursor-pointer'
  }
  return typeClassMap[type] || ''
}

const sizeClasses = computed(() => getSizeClasses(props.size))
const typeClasses = computed(() => getTypeClasses(props.type, props.disabled))

const iconSizeClasses = computed(() => {
  return props.size === 'small' ? 'h-4 w-4 my-auto' : 'h-6 w-6 my-auto'
})

// if button is disabled, prevent default behavior
// and stop propagation of the event
const handleClick = (event) => {
  if (props.disabled) {
    event.stopPropagation()
    event.preventDefault()
  }
}
</script>
