import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

import ApiService from '@/services/api.js'
import router from '@/router'

export const useStore = defineStore('mypath', () => {
  log('Store created')

  //*************************************************************
  // AUTH
  //*************************************************************
  const token = useStorage('token', localStorage.getItem('token') || '', localStorage)
  // first time initialization
  ApiService.setAuthToken(token.value)

  const logout = () => {
    localStorage.clear()
    token.value = ''
    ApiService.setAuthToken('')
    router.go({ name: 'home' })
    log('User logged out')
  }

  const signup = async (firebaseToken) => {
    try {
      const response = await ApiService.auth_signup(firebaseToken)
      const { _id, name, email, role, onBoardingStep, onBoardingLevel, subscription, endSubscription } = response
      user.value = { _id, name, email, role, onBoardingStep, onBoardingLevel, subscription, endSubscription }
      token.value = response.token
      log('User created succesfully')
      router.push({ name: 'dashboard' })
    } catch (error) {
      add_alert({ title: 'Account already exists', message: 'Please login instead', type: 'error' })
      console.error(error)
    }
  }

  const login = async (firebaseToken) => {
    try {
      const response = await ApiService.auth_login(firebaseToken)
      const { _id, name, email, role, onBoardingStep, onBoardingLevel, subscription, endSubscription, isActive, imgProfile } = response._doc
      user.value = { _id, name, email, role, onBoardingStep, onBoardingLevel, subscription, endSubscription, isActive, imgProfile }
      if (!isActive) {
        router.push({ name: 'welcome' })
      }
      token.value = response.token
      log('User logged in')
      if (role === 'Admin') {
        router.push({ name: 'admin-dashboard' })
      } else if (role === 'Teacher') {
        router.push({ name: 'teacher-dashboard' })
      } else {
        router.push({ name: 'dashboard' })
      }
    } catch (error) {
      localStorage.clear()
      add_alert({ title: 'Something went wrong!', message: 'Please create a new account first', type: 'error' })
    }
  }

  //******************************************
  // USER PROFILE
  //******************************************
  const user = useStorage('user.profile', null, localStorage, { serialize: JSON.stringify, deserialize: JSON.parse })
  const myPaths = useStorage('user.paths', [], localStorage, { serialize: JSON.stringify, deserialize: JSON.parse })
  const mySkills = useStorage('user.skills', [], localStorage, { serialize: JSON.stringify, deserialize: JSON.parse })

  // Get user profile from backend
  // userFetch()
  // userFetch({ noCohortsProgress: true }) -- returns the user without cohortsProgress
  // userFetch({ cohorts: true }) -- returns the user with cohorts populated
  // userFetch({ student: true }) -- returns the user only with necessary fields

  const userFetch = async (options = {}) => {
    log('User Fetch')
    try {
      let response
      if (options.cohorts) {
        response = await ApiService.user_get_cohorts_populated()
        user.value = response
        return response
      } else if (options.student) {
        response = await ApiService.user_get_cohorts_student()
        user.value = response
        return response
      } else {
        response = await ApiService.user_get()
        user.value = response
        return response
      }
    } catch (error) {
      console.error(error)
    }
  }

  const userFetchWithoutCohortsProgress = async () => {
    log('User Fetch')
    try {
      const response = await ApiService.user_get_no_cohorts_progress()
      user.value = response
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const userFetchCohortsPopulatedWithoutCohortsProgress = async () => {
    log('User Fetch')
    try {
      const response = await ApiService.user_get_cohorts_populated_without_cohorts_progress()
      user.value = response
      return response
    } catch (error) {
      console.error(error)
    }
  }

  /*   const userFetch = async () => {
      const response = await ApiService.user_get()
      const { _id, name, gitHub, email, role, onBoardingStep, onBoardingLevel, subscription, endSubscription, paths, skills, isAdmin, isActive, imgProfile, cohorts } = response

      const userValues = { _id, name, gitHub, email, role, onBoardingStep, onBoardingLevel, subscription, endSubscription, isAdmin, isActive, imgProfile, cohorts, skills }

      user.value = userValues
      myPaths.value = paths
      mySkills.value = skills.map(skill => ({
        certified: skill.certified,
        completed: skill.completed,
        nextTry: skill.nextTry,
        progress: skill.progress,
        ...skill.data
      }))

      log('User Fetch - User, MyPaths, MySkills set')
      return userValues
    } */
  const selectedUser = ref({})
  const users = ref([])

  const userFetchById = async (id, options = {}) => {
    log('User Fetch By Id')
    try {
      let response
      if (options.cohorts) {
        response = await ApiService.get_user_cohorts_populated(id)
        selectedUser.value = response
        return response
      } else {
        response = await ApiService.get_user(id)
        selectedUser.value = response
        return response
      }
    } catch (error) {
      console.error(error)
    }
  }

  // usersFetch()
  // usersFetch({ count: true }) -- returns the numbers of students

  const usersFetch = async (options = {}) => {
    log('Users Fetch')
    try {
      let response
      if (options.count) {
        return await ApiService.get_students_count()
      } else {
        response = await ApiService.get_users()
        users.value = response
      }
    } catch (error) {
      console.error(error)
    }
  }

  const createUser = async (user) => {
    log('User Created')
    try {
      const response = await ApiService.create_user(user)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const userUpdate = async (userChanges) => {
    try {
      log('User Update Profile')
      await ApiService.user_update_profile(userChanges)
    } catch (error) {
      console.error(error)
    }
  }

  const userUpdateById = async (id, user) => {
    try {
      log('User Updated By Id')
      const response = await ApiService.user_update_by_id(id, user)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const deleteUser = async (userId) => {
    log('User Deleted')
    try {
      const response = await ApiService.delete_user(userId)
      users.value = users.value.filter(user => user._id !== userId)
      return response
    } catch (error) {
      console.log(error)
    }
  }

  const userAddSkill = async (skill) => {
    try {
      const response = await ApiService.user_add_skill(skill)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const userUpdateSkill = async (skill) => {
    try {
      const response = await ApiService.user_update_skill(skill)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const userUpdateProgress = async (id, cohortId, skillId, updateData) => {
    try {
      const response = await ApiService.user_update_progress(id, cohortId, skillId, updateData)
      return response
    } catch (error) {
      console.error('Error updating user progress:', error)
    }
  }

  //******************************************
  // COHORTS
  //******************************************
  const cohorts = ref([])
  const cohort = ref(null)
  const newCohort = useStorage('user.newCohort', { name: '', description: '', modules: [], startDate: null, endDate: null, students: [], teachers: [] }, localStorage, { serialize: JSON.stringify, deserialize: JSON.parse })

  const cohortCreate = async (cohort) => {
    try {
      const response = await ApiService.create_cohort(cohort)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const generateCohortDescription = async (name) => {
    try {
      const response = await ApiService.generate_cohort_description(name)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const regenerateCohortDescription = async (name) => {
    try {
      const response = await ApiService.regenerate_cohort_description(cohort.value._id, name)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const cohortDelete = async () => {
    try {
      return await ApiService.delete_cohort(cohort.value._id)

    } catch (error) {
      console.error(error)
    }
  }

  // getCohorts()
  // getCohorts({ unpopulated: true }) -- returns cohorts unpopulated

  const getCohorts = async (options = {}) => {
    try {
      let response
      if (options.unpopulated) {
        response = await ApiService.get_cohorts_unpopulated()
        cohorts.value = response
        return response
      } else {
        response = await ApiService.get_cohorts()
        cohorts.value = response
        return response
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getCohort = async (id) => {
    try {
      const response = await ApiService.get_cohort(id)
      cohort.value = response
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const getCohortStudentLearn = async (id) => {
    try {
      const response = await ApiService.get_cohort_student_learn(id)
      cohort.value = response
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const getStudentCohort = async (id) => {
    try {
      const response = await ApiService.get_student_cohort(id)
      cohort.value = response
      return response
    } catch (error) {
      console.error(error)
    }
  }

  // Update Cohort
  // cohortUpdate()
  // cohortUpdate({ unpopulated: true }) -- returns the cohort unpopulated

  const cohortUpdate = async (id, cohort, options = {}) => {
    try {
      let response
      if (options.unpopulated) {
        response = await ApiService.update_cohort_unpopulated(id, cohort)
        cohort.value = response
        return response
      } else {
        response = await ApiService.update_cohort(id, cohort)
        cohort.value = response
        return response
      }
    } catch (error) {
      console.error(error)
    }
  }

  const inviteStudent = async (student) => {
    try {
      const response = await ApiService.invite_student(cohort.value._id, student)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const inviteTeacher = async (teacher) => {
    try {
      const response = await ApiService.invite_teacher(cohort.value._id, teacher)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const removeStudents = async (students) => {
    try {
      const response = await ApiService.remove_students(cohort.value, students)
      cohort.value = response
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const updateTeachers = async (teachers) => {
    try {
      const response = await ApiService.update_teachers(cohort.value, teachers)
      cohort.value = response
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const updateStudents = async (students) => {
    try {
      const response = await ApiService.update_students(cohort.value, students)
      cohort.value = response
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const sendWelcomeToCohortEmail = async (ids) => {
    try {
      const response = await ApiService.send_emails(ids, cohort.value)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  //******************************************
  // COHORT MODULES
  //******************************************

  const newModule = useStorage('user.newModule', {}, localStorage, { serialize: JSON.stringify, deserialize: JSON.parse })

  const getModule = async (id) => {
    try {
      const response = await ApiService.get_module(id)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const createModuleWithNoCohort = async (module) => {
    try {
      const response = await ApiService.create_module_with_no_cohort(module)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const moduleCreate = async (module, modulePos) => {
    try {
      log(`Module ${module.name} added to cohort ${cohort.value.name}`)
      const response = await ApiService.create_module(cohort.value, module, modulePos)
      cohort.value = response
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const moduleUpdate = async (module) => {
    try {
      log(`Module ${module.name} updated`)
      const response = await ApiService.update_module(module)
      cohort.value = await ApiService.get_cohort(cohort.value._id)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const moduleDelete = async (moduleId) => {
    try {
      log('Module deleted')
      const response = await ApiService.delete_module(moduleId)
      return response

    } catch (error) {
      console.error(error)
    }

  }


  //*************************************************************
  // LEARNING PATHS
  //*************************************************************

  const learningPaths = ref([])
  const userCustomPath = ref({})
  const userCustomPathSkills = useStorage('user.userCustomPathSkills', [], localStorage, { serialize: JSON.stringify, deserialize: JSON.parse })
  const pathSelected = useStorage('user.pathSelected', {}, localStorage, { serialize: JSON.stringify, deserialize: JSON.parse })

  // Filter learning paths by curated (codeSklls - not user created paths)
  const learningPathsCurated = computed(() =>
    learningPaths.value.filter(path => path.curated)
  )

  // Sort learning paths by percentage of completion of skills
  const sortedLearningPaths = computed(() => {
    return learningPathsCurated.value
      .map(path => ({ ...path, percentage: pathPercentageCompletion(path.skills) }))
      .sort((p1, p2) => p2.percentage - p1.percentage)
      .filter(path => path.percentage < 100)
  })

  const learningPathsFetch = async () => {
    try {
      const { paths } = await ApiService.learning_paths_get()
      learningPaths.value = paths
    } catch (error) {
      console.error(error)
    }
  }

  const getPathById = async (id) => {
    try {
      const response = await ApiService.get_path_by_id(id)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  /*
   * ********************************************
   * * PERCENTAGES CALCS
   * ********************************************
   */

  const calculatePercentage = (firstValue, secondValue) => {
    return Math.round((firstValue * 100) / secondValue)
  }

  /**
   * *path
   */

  const pathPercentageCompletion = (skills) => {
    const matchedSkills = mySkills.value.filter(
      userSkill => skills.map(skill => skill.name).includes(userSkill.name)
    )
    const completedSkills = matchedSkills.filter(skill => skill.completed === true)
    return Math.round((completedSkills.length / skills.length) * 100)
  }

  /**
   * *module
   */

  const moduleProgressPercentage = (user, module) => {
    const matchedSkills = user.skills.filter(userSkill => {
      return module.skills.some(pathSkill => pathSkill._id === userSkill.data._id)
    })
    const completedSkills = matchedSkills.filter(skill => skill.completed || skill.certified)

    if (Math.round((completedSkills.length / module.skills.length) * 100) >= 0 && Math.round((completedSkills.length / module.skills.length) * 100) <= 100) {
      return Math.round((completedSkills.length / module.skills.length) * 100)
    } else {
      return 0
    }

  }

  /**
   * *cohort
   */

  /**
   * *Calculates the percentage of active topics in a cohort out of the total topics in the cohort.
   * @param {Object} cohort - The cohort object.
   * @returns {Number} The percentage of active topics in the cohort.
   */
  const cohortProgressPercentage = (cohort) => {
    /*     const activeTopics = cohort.activeTopics.filter(activeTopic => {
          return cohort.modules.some(module =>
            module.skills.some(skill =>
              skill.topics.some(topic => topic._id === activeTopic._id)
            )
          )
        }).length

        if (!activeTopics) return 0

        const cohortTotalTopics = cohort?.modules.reduce((moduleAcc, module) => {
          return moduleAcc + module.skills.reduce((skillAcc, skill) => {
            return skillAcc + skill.topics.length
          }, 0)
        }, 0)

        const progress = calculatePercentage(activeTopics, cohortTotalTopics)
        return isNaN(progress) ? 0 : progress */

    // Count skills that have at least one active topic
    const availableSkills = cohort?.modules.reduce((moduleAcc, module) => {
      return moduleAcc + module.skills.reduce((skillAcc, skill) => {
        const hasActiveTopics = skill.topics.some(topic =>
          cohort.activeTopics.some(activeTopic => activeTopic._id === topic._id)
        )
        return skillAcc + (hasActiveTopics ? 1 : 0)
      }, 0)
    }, 0)

    if (!availableSkills) return 0

    // Count total skills in the cohort
    const cohortTotalSkills = cohort?.modules.reduce((moduleAcc, module) => {
      return moduleAcc + module.skills.length
    }, 0)

    // Calculate percentage based on available skills and total skills
    const progress = Math.ceil(availableSkills / cohortTotalSkills * 100)
    return isNaN(progress) ? 0 : progress
  }

  //
  // Returns % of active skills finished by a user in a given cohort
  //
  const cohortCompletionPercentage = (cohort, userData = user.value) => {
    /*  if (!cohort || !userData) return 0
    // Get total number of skills in the cohort
    const cohortSkills = cohort.modules.flatMap(module => module.skills).length
    // Get number of skills finished by the user
    const userCompletedSkills = userData.cohortsProgress
    .find(c => c.cohort === cohort._id)
    .skillsProgress
    .filter(s => s.finished)
    .length

    if(!userCompletedSkills || !cohortSkills) return 0

    const percentage = Math.ceil(userCompletedSkills / cohortSkills * 100)
    return isNaN(percentage) ? 0 : percentage */

    if (!cohort || !userData) return 0

    // Get total number of skills in the cohort
    const cohortSkills = cohort.modules?.flatMap(module => module.skills).length || 0
    if (cohortSkills === 0) return 0 // Verifica si hay skills en el cohort

    // Get cohort progress for the given cohort
    const userCohortProgress = userData.cohortsProgress?.find(c => c.cohort === cohort._id)
    if (!userCohortProgress) return 0 // Verifica si existe cohortProgress

    // Ensure skillsProgress exists in user progress
    const skillsProgress = userCohortProgress.skillsProgress
    if (!skillsProgress || skillsProgress.length === 0) return 0

    // Get number of skills finished by the user
    const userCompletedSkills = skillsProgress.filter(s => s.finished).length

    const percentage = Math.ceil(userCompletedSkills / cohortSkills * 100)
    return isNaN(percentage) ? 0 : percentage
  }

  /**
   * *quizzes count
   */
  const getOpenQuizzesCount = async (cohort) => {
    // Get lessons per active topics in the cohort
    const lessonsPerTopic = cohort?.activeTopics.map(topic => topic.lessons)
    if (!lessonsPerTopic) return 0

    // Get an array with all lessons ids in active topics
    const lessonsIds = lessonsPerTopic.flat()
    if (!lessonsIds) return 0

    const count = await ApiService.get_lessons_open_quizzes_count(lessonsIds)

    return count
  }

  /**
   * *Returns the percentage of quizzes completed by the given student in the given cohort.
   * @param {Number} quizzesCount - The total number of quizzes in the cohort.
   * @param {Object} student - The student object.
   * @param {String} cohortId - The id of the cohort.
   * @returns {Number} The percentage of quizzes completed.
   */
  const calculateQuizzesCompletedPercentage = (quizzesCount, student, cohortId) => {
    // if there are no open quizzes in the cohort
    if (quizzesCount === 0) return 0

    // If Student has already started any lesson
    const studentCohortProgress = student.cohortsProgress?.find(cp => cp.cohort === cohortId)
    if (!studentCohortProgress) return 0

    // Completed quizzes per Skill
    const quizzesCompletedArray = studentCohortProgress.skillsProgress.map(skill => skill?.completedQuizzes.length)

    // Total Completed Quizzes
    const totalQuizzesCompleted = quizzesCompletedArray.reduce((total, current) => total + current, 0) || 0
    if (totalQuizzesCompleted === 0) return 0

    const progress = calculatePercentage(totalQuizzesCompleted, quizzesCount)
    return isNaN(progress) ? 0 : progress
  }

  /**
   * topics
   */

  const cohortActiveTopicsProgress = (user, cohort) => {
    try {
      /*       if (!Array.isArray(cohort?.activeTopics) || !Array.isArray(user?.skills)) {
              return 0
            } */

      let totalActiveLessons = 0
      let totalCompletedLessons = 0

      cohort?.activeTopics.forEach(topic => totalActiveLessons += topic.lessons.length)

      user?.skills.forEach(skill => totalCompletedLessons += skill.progress.completedLessons.length)

      if (Math.round((totalCompletedLessons / totalActiveLessons) * 100) >= 0 && Math.round((totalCompletedLessons / totalActiveLessons) * 100) <= 100) {
        return Math.round((totalCompletedLessons / totalActiveLessons) * 100)
      } else {
        return 0
      }
    } catch (error) {
      console.error(error)
      return 0
    }
  }

  /****
   * *Returns % of active topics finished by a user in a given cohort
   */
  const skillCompletionPercentage = (userData = user.value, cohort, skillId) => {
    // Find user's progress for the specific skill in the cohort
    const userSkillProgress = userData.cohortsProgress
      .find(c => c.cohort === cohort._id)
      ?.skillsProgress
      .find(sp => sp.skill === skillId)

    if (!userSkillProgress) return 0

    // Find the skill in the cohort
    const skillInCohort = cohort.modules
      .flatMap(module => module.skills)
      .find(skill => skill._id === skillId)

    if (!skillInCohort) return 0

    // Get active topics for the skill in the cohort
    const activeTopics = cohort.activeTopics.filter(topic =>
      skillInCohort.topics.some(skillTopic => skillTopic._id === topic._id)
    )

    // Calculate percentage based on completed topics
    const totalTopics = activeTopics.length
    const completedTopics = userSkillProgress.completedTopics.filter(ct =>
      activeTopics.some(topic => topic._id === ct.topic)
    ).length

    const percentage = Math.ceil((completedTopics / totalTopics) * 100)
    return isNaN(percentage) ? 0 : percentage
  }

  const userAddPath = async (learningPath) => {
    try {
      const response = await ApiService.user_add_path(learningPath)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  async function generatePath(path) {
    try {
      const response = await ApiService.generate_path(path)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  async function reGeneratePathDescription(path) {
    try {
      const response = await ApiService.regenerate_path_description(path)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  async function reGeneratePathGoals(path) {
    try {
      const response = await ApiService.regenerate_path_goals(path)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  async function updatePath(id, path) {
    try {
      const response = await ApiService.update_path(id, path)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  async function createCustomPath(path) {
    try {
      const response = await ApiService.create_custom_path(path)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  async function updateCustomPath(id, path) {
    try {
      const response = await ApiService.update_custom_path(id, path)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  async function deleteCustomPath(id) {
    try {
      const response = await ApiService.delete_custom_path(id)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  //******************************************
  // USER CATEGORIES
  //******************************************
  const categories = ref([])

  const categoriesFetch = async () => {
    try {
      const response = await ApiService.get_categories()
      categories.value = response.categories
    } catch (error) {
      console.error(error)
    }
  }

  //******************************************
  // USER SKILLS
  //******************************************
  const skills = ref([])
  const newSkill = useStorage('user.newSkill', [], localStorage, { serialize: JSON.stringify, deserialize: JSON.parse })

  const getOneSkill = async (id) => {
    try {
      const response = await ApiService.get_one_skill(id)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const skillNameFormat = async (name) => {
    try {
      const response = await ApiService.skill_name_format(name)
      return response.name
    } catch (error) {
      console.error(error)
    }
  }

  // TODO: Why is this different than getOneSkill?
  const getSkillById = async (id) => {
    try {
      const response = await ApiService.get_skill(id)
      skill.value = response
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const skill = ref(null)

  const skillCreate = async (skill) => {
    try {
      const response = await ApiService.create_skill(skill)
      skill.value = response
      skills.value.push(response)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  // skillsFetch()
  // skillsFetch({ unpopulated: true }) -- returns the skills unpopulated

  const skillsFetch = async (options = {}) => {
    try {
      let response
      if (options.unpopulated) {
        response = await ApiService.get_skills_unpopulated()
        skills.value = response
        return response
      } else {
        response = await ApiService.get_skills()
        skills.value = response
        return response
      }
    } catch (error) {
      console.error(error)
    }
  }

  const skillsActiveFetch = async () => {
    try {
      const response = await ApiService.get_active_skills()
      skills.value = response
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const skillsActiveUnpopuledFetch = async () => {
    try {
      const response = await ApiService.get_active_skills_unpopulated()
      skills.value = response
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const skillSave = async (id, skillChanges) => {
    try {
      const response = await ApiService.update_skill(id, skillChanges)
      await skillsFetch()
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const deleteSkill = async (id) => {
    try {
      const response = await ApiService.delete_skill(id)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  // All skills that the user doesn't have
  const skillsPending = computed(() => {
    const userSkills = mySkills.value.map((userSkill) => userSkill.name.toLowerCase())
    return skills?.value.filter(skill => !userSkills.includes(skill.name.toLowerCase()))
  })

  // Skills grouped by category
  const mySkillsByCategory = computed(() => {
    const categories = {}
    mySkills.value.forEach(skill => {
      if (categories[skill.category]) {
        categories[skill.category].push(skill)
      } else {
        categories[skill.category] = [skill]
      }
    })
    return categories
  })

  // Filter skills by Category
  const skillsByCategory = (category) => {
    if (!skillsPending.value) return []
    const userSkills = mySkills.value.map(userSkill => userSkill.name.toLowerCase())

    return skillsPending.value
      .filter(skill =>
        (category === 'all' || !category || skill.category.toLowerCase().includes(category.toLowerCase())) &&
        !userSkills.includes(skill.name.toLowerCase())
      )
      // Limit to 15 skills if category is empty, otherwise return all filtered skills.
      .slice(0, category === '' ? 15 : undefined)
  }

  // Filter skills by Search Name
  const skillsBySearch = searchText => {
    if (!skillsPending.value) return []

    return skillsPending?.value
      .filter(skill => skill.name.toLowerCase().includes(searchText.toLowerCase()))
  }


  const skillUpdate = (updatedSkill) => {
    skill.value = updatedSkill
  }

  const handleAddSkill = (skillName, from, to) => {
    const index = from.findIndex(skill => skill.name === skillName.name)
    if (index !== -1) {
      from.splice(index, 1)
      to.push(skillName)
    }
  }

  //******************************************
  // USER SKILLS - AI METHODS
  //******************************************
  const skillGenerate = async (name) => {
    try {
      const response = await ApiService.skill_generate(name)
      newSkill.value = response
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const skillGenerateDescription = async (name) => {
    try {
      const response = await ApiService.skill_generate_description(name)
      newSkill.value = response
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const skillGenerateGoal = async (skillName, skillGoals, skillDescription, newGoal) => {
    try {
      const response = await ApiService.skill_generate_goal(skillName, skillGoals, skillDescription, newGoal)
      newSkill.value.goals = response
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const skillGenerateGoals = async () => {
    try {
      const { goals } = await ApiService.skill_generate_goals(newSkill.value.name, newSkill.value.description)
      newSkill.value.goals = goals
      return goals
    } catch (error) {
      console.error(error)
    }
  }

  const skillRegenerateDescription = async (skill) => {
    try {
      return await ApiService.regenerate_skill_description(skill)
    } catch (error) {
      console.error(error)
    }
  }

  const skillRegenerateGoals = async (skill) => {
    try {
      return await ApiService.regenerate_skill_goals(skill)
    } catch (error) {
      console.error(error)
    }
  }

  const skillSummarizeGoals = async (goals, name, description) => {
    try {
      const response = await ApiService.summarize_goals(goals, name, description)
      newSkill.value.goals = response
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const skillExpandGoals = async (goals, name, description) => {
    try {
      const response = await ApiService.expand_goals(goals, name, description)
      newSkill.value.goals = response
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const skillSummarizeTopics = async (topics, goals, name, description) => {
    try {
      const response = await ApiService.summarize_topics(topics, goals, name, description)
      newSkill.value.topics = response
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const skillExpandTopics = async (topics, goals, name, description) => {
    try {
      const response = await ApiService.expand_topics(topics, goals, name, description)
      newSkill.value.topics = response
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const skillProgressPercentage = (/* user, skill */) => {
    /*     let userSkill = user?.skills.find(s => s.data._id === skill._id)
        let totalLessons = skill?.topics.reduce((a, b) => a + (b.lessons?.length || 0), 0)
        if (!totalLessons || totalLessons === 0 || !userSkill) return 0

        return parseInt((userSkill.progress.completedLessons.length * 100 / totalLessons).toFixed(0)) */
    return 0
  }

  const skillStudentProgressPercentage = (skill) => {
    const getTopicsTaughtCount = () => {
      const activeTopicsTaughtCount = cohort.value?.activeTopics.filter(t => skill.topics.find(s => s._id === t._id)).length
      return activeTopicsTaughtCount
    }
    const getTopicsCompletedCount = () => {

      const cohortProgress = user.value.cohortsProgress.find(c => c.cohort == cohort.value?._id)
      if (!cohortProgress) return 0
      const skillInProgress = cohortProgress.skillsProgress.find(s => s.skill === skill._id)
      if (!skillInProgress) return 0
      if (!skillInProgress.completedTopics) return 0
      const topicsCompleted = skillInProgress.completedTopics.length
      return topicsCompleted
    }
    return (() => {
      const progress = Math.round(getTopicsCompletedCount() * 100 / getTopicsTaughtCount())
      return isNaN(progress) ? 0 : progress
    })()
  }

  //*************************************************************
  // TOPICS
  //*************************************************************
  const topic = ref(null)

  const createTopics = async (id) => {
    try {
      return await ApiService.create_topics(id)
    } catch (error) {
      console.error(error)
    }
  }

  const topicsCreateMany = async (id, topics) => {
    try {
      return await ApiService.topics_create_many(id, topics)
    } catch (error) {
      console.error(error)
    }
  }

  const generateTopics = async (numTopics) => {
    try {
      const { topics } = await ApiService.generate_topics(newSkill.value, numTopics)
      newSkill.value.topics = topics
      return topics
    } catch (error) {
      console.error(error)
    }
  }

  const generateTopic = async (skillName, skillTopics, skillDescription, newGoal) => {
    try {
      const { topics } = await ApiService.generate_topic(skillName, skillTopics, skillDescription, newGoal)
      newSkill.value.topics = topics
      return topics
    } catch (error) {
      console.error(error)
    }
  }

  const topicCreate = async (topic) => {
    try {
      const newTopic = await ApiService.create_topic(topic)
      return newTopic
    } catch (error) {
      console.error(error)
    }
  }

  const topicSave = async (id, topic) => {
    try {
      topic.value = await ApiService.update_topic(id, topic)
      // find the topic in the skill topics array and update it
      const index = skill.value.topics.findIndex(t => t._id === id)
      skill.value.topics.splice(index, 1, topic.value)
      return topic.value
    } catch (error) {
      console.error(error)
    }
  }

  const topicDelete = async (id) => {
    try {
      return await ApiService.delete_topic(id)
    } catch (error) {
      console.error(error)
    }
  }

  const generateTopicInfo = async (topic) => {
    try {
      return await ApiService.generate_topic_info(topic)
    } catch (error) {
      console.error(error)
    }
  }

  const generateTopicGoal = async (topicId, goalsCopy, input) => {
    try {
      return await ApiService.generate_topic_goal(topicId, goalsCopy, input)
    } catch (error) {
      console.error(error)
    }
  }

  const generateTopicDescription = async (topicId) => {
    try {
      return await ApiService.generate_topic_description(topicId)
    } catch (error) {
      console.error(error)
    }
  }

  //*************************************************************
  // LESSONS
  //*************************************************************
  const lesson = ref(null)

  const getOneLesson = async (lesson_id) => {
    try {
      const oneLesson = await ApiService.get_lesson(lesson_id)
      lesson.value = oneLesson
      return oneLesson
    } catch (error) {
      console.error(error)
    }

    //lesson.value = topic.value.lessons.find(lesson => lesson._id === lesson_id)
  }

  const getLessonsCount = async () => {
    try {
      const lessonsCount = await ApiService.get_lessons_count()
      return lessonsCount
    } catch (error) {
      console.error(error)
    }
  }


  const createLesson = async (lesson) => {
    try {
      const newLesson = await ApiService.create_lesson(lesson, topic.value._id)
      topic.value.lessons.push(newLesson)
      return newLesson
    } catch (error) {
      console.error(error)
    }
  }

  const deleteLesson = async (id) => {
    try {
      return await ApiService.delete_lesson(id)
    } catch (error) {
      console.error(error)
    }
  }

  const generateLesson = async (id, lessons, newLesson, genOption) => {
    try {
      log('Generating Lesson')
      return await ApiService.generate_lesson(id, lessons, newLesson, genOption)
    } catch (error) {
      console.error(error)
    }
  }

  const generateLessonGoal = async (lessonId, goalsCopy, input) => {
    try {
      log('Generating Lesson Goal')
      return await ApiService.generate_lesson_goal(lessonId, goalsCopy, input)
    } catch (error) {
      console.error(error)
    }
  }

  const generateLessons = async (id) => {
    try {
      log('Generating Lessons', id)
      return await ApiService.generate_lessons(id)
    } catch (error) {
      console.error(error)
    }
  }

  const generateLessonsOneshot = async (skillId) => {
    try {
      log('Generating Lessons Oneshot')
      return await ApiService.generate_lessons_oneshot(skillId)
    } catch (error) {
      console.error(error)
    }
  }

  const lessonSave = async (id, lesson) => {
    try {
      log('Updating Lesson', id)
      return await ApiService.update_lesson(id, lesson)
    } catch (error) {
      console.error(error)
    }
  }

  //*************************************************************
  // LESSON NOTES
  //*************************************************************
  const createNote = async (note) => {
    try {
      return await ApiService.create_note(note)
    } catch (error) {
      console.error(error)
    }
  }

  const deleteNote = async (id) => {
    try {
      return await ApiService.delete_note(id)
    } catch (error) {
      console.error(error)
    }
  }

  //*************************************************************
  // BLOCKS
  //*************************************************************
  const addBlock = async (lesson_id, newBlock, position) => {
    try {
      log(`adding ${newBlock.__t} block to lesson ${lesson_id}`)
      return await ApiService.add_block(lesson_id, newBlock, position)
    } catch (error) {
      console.error(error)
    }
  }

  const updateBlock = async (newBlock) => {
    try {
      log('updating block', newBlock._id)
      return await ApiService.update_block(newBlock)
    } catch (error) {
      console.error(error)
    }
  }

  const removeBlock = async (blockId, lessonId) => {
    try {
      log('Deleting block', blockId)
      return await ApiService.remove_block(blockId, lessonId)
    } catch (error) {
      console.error(error)
    }
  }

  const generateContent = async (lessonId, skillName, tone) => {
    try {
      log('Generating Lesson Content')
      return await ApiService.generate_content(lessonId, skill.value.name, tone)
    } catch (error) {
      console.error(error)
    }
  }

  const generateContentOneshot = async (skillId) => {
    try {
      log('Generating Lesson Content')
      return await ApiService.generate_content_oneshot(skillId)
    } catch (error) {
      console.error(error)
    }
  }

  const generateTFBlock = async (id, content) => {
    try {
      log('Creating Lesson TF Block')
      const response = await ApiService.generate_tf_block(id, content)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const generateMultiBlock = async (id, content) => {
    try {
      log('Creating Lesson Multi Block')
      const response = await ApiService.generate_multi_block(id, content)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const generateTextBlock = async (skill, lesson, input) => {
    try {
      log('Creating Lesson Text Block')
      const response = await ApiService.generate_text_block(skill, lesson, input)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const generateJustifyBlock = async (id, content, skillName) => {
    try {
      log('Creating Lesson Justify Block')
      const response = await ApiService.generate_justify_block(id, content, skillName)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const verifyJustifyAnswer = async (quizId, answer) => {
    try {
      log('Verifying Justify Answer')
      const response = await ApiService.verify_justify_answer(quizId, answer)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const modifyContent = async (content, input) => {
    try {
      log('Modifying Content')
      const response = await ApiService.modify_content(content, input)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const splitContent = async (block) => {
    /*
      const block = {
        id: block.id,
        index: block.index,
        content: blockContent
      }
    */
    try {
      log('Splitting Content')
      const { blocks } = await ApiService.split_content(block)
      const firstBlock = await updateBlock({ __t: 'text-block', _id: block.id, content: blocks[0].content })
      const newBlock = await addBlock(lesson.value._id, { __t: 'text-block', content: blocks[1].content }, block.index)
      lesson.value.blocks.splice(block.index, 1, firstBlock, newBlock)
      return blocks
    } catch (error) {
      console.error(error)
    }
  }
  //******************************************
  // SLIDES
  //******************************************
  const generateSlides = async (lessonId) => {
    try {
      log('Creating Slides')
      const response = await ApiService.generate_slides(lessonId)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const updateSlides = async (lessonId, presentation) => {
    try {
      log('Updating Slides')
      const response = await ApiService.update_slides(lessonId, presentation)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  //******************************************
  // ALERTS
  //******************************************
  const alerts = ref([])

  let alertId = 0
  const ALERT_TIMEOUT = 3000

  const add_alert = ({ title, message, type }) => {
    log(`Alert added | ${type} | ${title} - ${message}`)
    const id = alertId++
    const timer = setTimeout(() => remove_alert(id), ALERT_TIMEOUT)
    alerts.value = [...alerts.value, { id, title, message, type, show: true, timer }]
  }

  const remove_alert = (id) => {
    log('Alert removed')
    const index = alerts.value.findIndex(alert => alert.id === id)
    if (index !== -1) {
      clearTimeout(alerts.value[index].timer)
      alerts.value.splice(index, 1)
    }
  }

  //******************************************
  // HEADER
  //******************************************
  const headerBreadcrumb = ref([])
  const headerButton = ref({})
  const headerButton2 = ref({})
  const headerDropdown = ref([])
  const headerSelected = ref(null)
  const showSlides = ref(null)
  const slidesFullscreen = ref(null)

  //******************************************
  // CLOUDINARY
  //******************************************
  const uploadImage = async (file, folder) => {
    try {
      const response = ApiService.upload_image(file, folder)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const profileUpdatePhoto = async (userId, file, folder) => {
    try {
      const response = ApiService.profile_update_photo(userId, file, folder)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  //******************************************
  // Event-Logs
  //******************************************
  const getEventLogs = async () => {
    try {
      log('Get all Events log')
      const response = await ApiService.get_event_logs()
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const getAdminEventLogs = async () => {
    try {
      log('Get admin Event logs')
      const response = await ApiService.get_admin_event_logs()
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const getEventLogsByUser = async (userId) => {
    try {
      log('Get Event of user')
      const response = await ApiService.get_event_logs_by_user(userId)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const createEventLog = async (eventLog) => {
    try {
      log('Event')
      const response = ApiService.create_event_log(eventLog)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const getStudentEventLogs = async (userId) => {
    try {
      log('Get Event of student')
      const response = await ApiService.get_student_event_logs(userId)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  //******************************************
  // ChatBot
  //******************************************
  const chatBot = async (chat) => {
    try {
      log('Chatting')
      const response = await ApiService.chatbot(chat)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  //*************************************************************
  // Assistant & Threads
  //*************************************************************
  const userChat = ref({ messages: [], topicId: null })

  const createThread = async (userId, topicId) => {
    try {
      log('Creating Thread')
      const response = await ApiService.create_thread(userId, topicId)
      user.value.conversations.push({ threadId: response.threadId, topicId: topicId })
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const sendNewMessage = async (threadId, assistantId, message) => {
    try {
      log('Sending Message')
      const response = await ApiService.send_new_message(threadId, assistantId, message)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const getThreadMessages = async (threadId) => {
    try {
      log('Get Messages')
      const response = await ApiService.get_thread_messages(threadId)
      return response
    } catch (error) {
      console.error(error)
    }
  }

  const updateMessage = async (id, messageChanges) => {
    try {
      log('Update Message')
      const response = await ApiService.update_message(id, messageChanges)
      return response
    } catch (error) {
      console.error(error)
    }
  }


  return {
    //Authentication
    token,
    signup,
    login,
    logout,
    // User info
    user,
    userFetchById,
    userFetch,
    userFetchWithoutCohortsProgress,
    userFetchCohortsPopulatedWithoutCohortsProgress,
    createUser,
    userUpdate,
    userUpdateById,
    deleteUser,
    userAddSkill,
    userUpdateSkill,
    usersFetch,
    users,
    selectedUser,
    userUpdateProgress,
    // User Skills
    mySkills,
    skillsPending,
    mySkillsByCategory,
    handleAddSkill,
    skillProgressPercentage,
    skillStudentProgressPercentage,
    // User Paths
    myPaths,
    userAddPath,
    userCustomPath,
    userCustomPathSkills,
    // Skills
    getOneSkill,
    skillNameFormat,
    newSkill,
    // Cohorts,
    cohorts,
    cohort,
    newCohort,
    cohortCreate,
    generateCohortDescription,
    regenerateCohortDescription,
    cohortDelete,
    getCohorts,
    getCohort,
    getCohortStudentLearn,
    getStudentCohort,
    cohortUpdate,
    inviteStudent,
    inviteTeacher,
    removeStudents,
    updateTeachers,
    updateStudents,
    sendWelcomeToCohortEmail,
    // Cohort modules
    newModule,
    moduleCreate,
    moduleUpdate,
    moduleDelete,
    getModule,
    createModuleWithNoCohort,
    cohortProgressPercentage,
    cohortActiveTopicsProgress,
    skillCompletionPercentage,
    cohortCompletionPercentage,
    getOpenQuizzesCount,
    calculateQuizzesCompletedPercentage,
    // Learning paths
    learningPaths,
    getPathById,
    pathSelected,
    learningPathsFetch,
    learningPathsCurated,
    sortedLearningPaths,
    pathPercentageCompletion,
    moduleProgressPercentage,
    generatePath,
    reGeneratePathDescription,
    reGeneratePathGoals,
    updatePath,
    createCustomPath,
    updateCustomPath,
    deleteCustomPath,
    // Topics
    topic,
    createTopics,
    topicsCreateMany,
    generateTopics,
    generateTopic,
    topicCreate,
    topicSave,
    topicDelete,
    generateTopicInfo,
    generateTopicGoal,
    generateTopicDescription,
    // Lessons
    generateLesson,
    generateLessonGoal,
    generateLessons,
    generateLessonsOneshot,
    lessonSave,
    generateContent,
    generateContentOneshot,
    generateTFBlock,
    generateMultiBlock,
    generateJustifyBlock,
    verifyJustifyAnswer,
    addBlock,
    updateBlock,
    removeBlock,
    getLessonsCount,
    generateTextBlock,
    modifyContent,
    splitContent,
    // Lesson Notes
    createNote,
    deleteNote,
    // Categories
    categories,
    categoriesFetch,
    // CodeSkills Skills
    getSkillById,
    skills,
    skillsByCategory,
    skillsBySearch,
    skillCreate,
    skillGenerate,
    skillGenerateDescription,
    skillGenerateGoal,
    skillGenerateGoals,
    skillRegenerateDescription,
    skillRegenerateGoals,
    skillSummarizeGoals,
    skillExpandGoals,
    skillSummarizeTopics,
    skillExpandTopics,
    skillsFetch,
    skillsActiveFetch,
    skillsActiveUnpopuledFetch,
    skillSave,
    deleteSkill,
    skillUpdate,
    skill,
    lesson,
    getOneLesson,
    createLesson,
    deleteLesson,
    // Slides
    generateSlides,
    updateSlides,
    // Utils
    alerts,
    add_alert,
    remove_alert,
    // Header
    headerBreadcrumb,
    headerButton,
    headerButton2,
    headerDropdown,
    headerSelected,
    showSlides,
    slidesFullscreen,
    // Cloudinary
    uploadImage,
    profileUpdatePhoto,
    // Event-Log
    getEventLogs,
    getAdminEventLogs,
    createEventLog,
    getEventLogsByUser,
    getStudentEventLogs,
    // chatBot
    chatBot,
    // Assistant & Threads
    userChat,
    createThread,
    sendNewMessage,
    getThreadMessages,
    updateMessage
  }
})


const log = (...msgs) => {
  const formattedMsgs = msgs.map((msg) => {
    if (msg && typeof msg === 'object' && 'value' in msg) {
      return msg.value
    }
    return typeof msg === 'object' ? 'Unserializable object' : msg
  })
  console.info(`[ Store ]  ${formattedMsgs.join(' ')}`)
}

