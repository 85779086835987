<!-- Componente Hijo (Icono) -->
<template>
  <component v-if="!isSvg" :is="iconComponent" :class="`${props.size} ${props.className}`" aria-hidden="true" />
  <div v-else :class="`${props.size} ${props.className}`" aria-hidden="true" v-html="iconComponentContent"></div>
</template>

<script setup>
import { computed } from 'vue'
import * as AllIcons from '@heroicons/vue/24/outline'
// Standard Size 24x24
import DashboardSvg from '@/assets/icons/dashboard.svg?raw'
import CohortsSvg from '@/assets/icons/cohorts.svg?raw'
import SkillsSvg from '@/assets/icons/skills.svg?raw'
import UsersSvg from '@/assets/icons/users.svg?raw'
import LogoutSvg from '@/assets/icons/logout.svg?raw'
import SearchSvg from '@/assets/icons/search.svg?raw'
import ProfileSvg from '@/assets/icons/profile.svg?raw'
import CollapseVerSvg from '@/assets/icons/collapseV.svg?raw'
import UncollapseVerSvg from '@/assets/icons/uncollapseV.svg?raw'
import BinSvg from '@/assets/icons/bin.svg?raw'
import OrderSvg from '@/assets/icons/order.svg?raw'
import BulbSvg from '@/assets/icons/bulb.svg?raw'
import ThunderSvg from '@/assets/icons/thunder.svg?raw'
import InfoSvg from '@/assets/icons/info.svg?raw'
import CheckSvg from '@/assets/icons/check.svg?raw'
import LessonSvg from '@/assets/icons/lesson.svg?raw'
import DeleteSvg from '@/assets/icons/delete.svg?raw'
import DragSvg from '@/assets/icons/drag.svg?raw'
import CollapseHorSvg from '@/assets/icons/collapseH.svg?raw'
import UncollapseHorSvg from '@/assets/icons/uncollapseH.svg?raw'
import EditSvg from '@/assets/icons/edit.svg?raw'
import ExpandSvg from '@/assets/icons/expand.svg?raw'
import ReduceSvg from '@/assets/icons/reduce.svg?raw'
import DateSvg from '@/assets/icons/date.svg?raw'
import NoteSvg from '@/assets/icons/note.svg?raw'
import PencilSvg from '@/assets/icons/pencil.svg?raw'
import ResizeSvg from '@/assets/icons/resize.svg?raw'
import GithubSvg from '@/assets/icons/github.svg?raw'
import TextSvg from '@/assets/icons/text.svg?raw'
import TrueFalseSvg from '@/assets/icons/trueFalse.svg?raw'
import MultipleChoice from '@/assets/icons/multipleChoice.svg?raw'
import SlidesSvg from '@/assets/icons/slides.svg?raw'
import ContentSvg from '@/assets/icons/content.svg?raw'
import TopicSvg from '@/assets/icons/topic.svg?raw'
import SendSvg from '@/assets/icons/send.svg?raw'
import StadisticSvg from '@/assets/icons/stadistic.svg?raw'
import ReloadSvg from '@/assets/icons/reload.svg?raw'
import SplitSvg from '@/assets/icons/split.svg?raw'
import ModifySvg from '@/assets/icons/modify.svg?raw'
import SkillTopicSvg from '@/assets/icons/skillTopic.svg?raw'
import ChatBotSvg from '@/assets/icons/chatbot.svg?raw'
import OpenUISvg from '@/assets/icons/openUI.svg?raw'
import AlertPositiveSvg from '@/assets/icons/alertPositive.svg?raw'
import AlertNegativeSvg from '@/assets/icons/alertNegative.svg?raw'
import QuizSvg from '@/assets/icons/quiz.svg?raw'
import ThumbsUpSvg from '@/assets/icons/thumbsUpOutline.svg?raw'
import ThumbsDownSvg from '@/assets/icons/thumbsDownOutline.svg?raw'
import ThumbsUpSolidSvg from '@/assets/icons/thumbsUpSolid.svg?raw'
import ThumbsDownSolidSvg from '@/assets/icons/thumbsDownSolid.svg?raw'
import JustifySvg from '@/assets/icons/justify.svg?raw'
import WheelSvg from '@/assets/icons/wheel.svg?raw'


const props = defineProps({
  icon: {
    type: String,
    required: true
  },
  className: String,
  size: {
    type: String,
    default: 'w-6 h-6'
  }
})

const allowedIcons = {
  'action_menu': AllIcons.EllipsisVerticalIcon,
  'admin': AllIcons.Cog8ToothIcon,
  'ai': AllIcons.SparklesIcon,
  'alert': AllIcons.ExclamationCircleIcon,
  'arrow_access': AllIcons.ArrowUpRightIcon,
  'bin': BinSvg,
  'bulb': BulbSvg,
  'cancel': AllIcons.XCircleIcon,
  'caution': AllIcons.ExclamationTriangleIcon,
  'chatbot': ChatBotSvg,
  'check': CheckSvg,
  'chevron_down': AllIcons.ChevronDownIcon,
  'chevron_left': AllIcons.ChevronLeftIcon,
  'chevron_right': AllIcons.ChevronRightIcon,
  'chevron_up': AllIcons.ChevronUpIcon,
  'close': AllIcons.XMarkIcon,
  'cohorts': CohortsSvg,
  'collapse_hor': CollapseHorSvg,
  'collapse_ver': CollapseVerSvg,
  'confirm': AllIcons.CheckCircleIcon,
  'content': ContentSvg,
  'dashboard': DashboardSvg,
  'date': DateSvg,
  'delete': DeleteSvg,
  'drag': DragSvg,
  'edit': EditSvg,
  'ellipsis': AllIcons.EllipsisHorizontalIcon,
  'email': AllIcons.EnvelopeIcon,
  'expand': ExpandSvg,
  'github': GithubSvg,
  'info': InfoSvg,
  'justify': JustifySvg,
  'lesson': LessonSvg,
  'logout': LogoutSvg,
  'multiple_choice': MultipleChoice,
  'note': NoteSvg,
  'open_ui': OpenUISvg,
  'order': OrderSvg,
  'pencil': PencilSvg,
  'plus': AllIcons.PlusIcon,
  'profile': ProfileSvg,
  'pw_hidden': AllIcons.EyeSlashIcon,
  'pw_visible': AllIcons.EyeIcon,
  'reduce': ReduceSvg,
  'resize': ResizeSvg,
  'search': SearchSvg,
  'send': SendSvg,
  'skills': SkillsSvg,
  'slide-left': AllIcons.ArrowLeftCircleIcon,
  'slide-right': AllIcons.ArrowRightCircleIcon,
  'slides': SlidesSvg,
  'star': AllIcons.StarIcon,
  'text': TextSvg,
  'thumbs_up_outline': ThumbsUpSvg,
  'thumbs_down_outline': ThumbsDownSvg,
  'thumbs_up_solid': ThumbsUpSolidSvg,
  'thumbs_down_solid': ThumbsDownSolidSvg,
  'thunder': ThunderSvg,
  'topic': TopicSvg,
  'true_false': TrueFalseSvg,
  'uncollapse_hor': UncollapseHorSvg,
  'uncollapse_ver': UncollapseVerSvg,
  'users': UsersSvg,
  'stadistic': StadisticSvg,
  'reload': ReloadSvg,
  'split': SplitSvg,
  'modify': ModifySvg,
  'skillTopic': SkillTopicSvg,
  'alert_positive': AlertPositiveSvg,
  'alert_negative': AlertNegativeSvg,
  'quiz': QuizSvg,
  'wheel': WheelSvg
}

const iconComponent = computed(() => {
  return allowedIcons[props.icon] || null
})

const isSvg = computed(() => {
  return typeof iconComponent.value === 'string'
})

const iconComponentContent = computed(() => {
  return isSvg.value ? iconComponent.value : ''
})
</script>
