<template>
  <div>
    <RouterView />

    <!-- Global notification live region -->
    <!-- To add notifications, call this from anywhere in the app:
      store.add_alert({ type, title, message })
    -->
    <div aria-live="assertive"
      class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50">
      <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
        <AlertBox v-for="alert in alerts" :key="alert.id" :id="alert.id" :type="alert.type" :title="alert.title"
          :message="alert.message" @close="store.remove_alert" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useStore } from '@/store'

const store = useStore()
const { alerts } = storeToRefs(store)
</script>
