<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-50">
      <div class="fixed inset-0 overflow-y-auto w-[100vw] h-[100vh] z-[100]">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel>
              <div
                class="fixed transform overflow-hidden text-left align-middle transition-all flex flex-col flex-wrap gap-x-2 justify-center items-center text-white py-8 px-4 top-[calc(50%+70px)] left-1/2 -translate-x-1/2 -translate-y-1/2 z-[2000] pointer-events-none">
                <div class="mb-16 ">
                  <div class="w-[300px] h-[300px]">
                    <Loader />
                  </div>
                </div>

                <DialogTitle as="h1" class="mt-6 text-3xl font-bold w-full text-center select-none">
                  {{ props.title }}
                </DialogTitle>
                <DialogDescription>
                  <h2 class="text-center mt-8 select-none">{{ randomMessage }}</h2>
                </DialogDescription>

              </div>
              <div id="neoncursor" class="neoncursor fixed z-10" />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, onMounted, nextTick, watch } from 'vue'
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription
} from '@headlessui/vue'
import Loader from '@/components/loader.vue'
import { neonCursor } from 'https://unpkg.com/threejs-toys@0.0.8/build/threejs-toys.module.cdn.min.js'

const emit = defineEmits(['closeModal'])

const props = defineProps({
  title: String,
  isOpen: {
    type: Boolean,
    default: false
  }
})

onMounted(() => {
  if (props.isOpen) {
    nextTick(() => {
      const neonCursorElement = document.getElementById('neoncursor')
      if (neonCursorElement) {
        neonCursor({
          el: neonCursorElement,
          shaderPoints: 16,
          curvePoints: 80,
          curveLerp: 0.5,
          radius1: 5,
          radius2: 30,
          velocityTreshold: 30,
          sleepRadiusX: 100,
          sleepRadiusY: 100,
          sleepTimeCoefX: 0.0025,
          sleepTimeCoefY: 0.0025
        })
      } else {
        console.error('neonCursor element not found')
      }
    })
  }
})

function closeModal() {
  emit('closeModal')
}

const loadingMessages = [
  'Success is not just about learning fast, but learning smart.',
  'Patience is a skill, and today you\'re mastering it!',
  'Your future self is proud of the progress you\'re making today.',
  'Each moment of waiting is a step closer to your goals.',
  'In learning, every second counts – even the ones in between.',
  'Great things take time, and you\'re on the path to greatness.',
  'Loading knowledge… because the best things are worth the wait.',
  'Learning is a journey, not a race. Enjoy every moment!',
  'Small pauses make way for big ideas.',
  'Your brain is gearing up for something amazing!',
  'In the world of coding, even waiting is part of the process.',
  'This loading screen is just a brief intermission in your epic journey.',
  'Good things come to those who code… and wait.',
  'While you wait, imagine all the possibilities ahead.',
  'Your success story is loading…',
  'Dream big, code bigger.',
  'Every pause is a chance to reflect on how far you\'ve come.',
  'Learning never stops, not even for loading screens.',
  'Your next breakthrough is just around the corner!',
  'Coding takes time, but every line brings you closer to mastery.'
]

const randomMessage = ref(loadingMessages[Math.floor(Math.random() * loadingMessages.length)])

watch(() => props.isOpen, (newVal) => {
  if (newVal) {
    randomMessage.value = loadingMessages[Math.floor(Math.random() * loadingMessages.length)]
  }
})

watch(() => props.isOpen, (newVal) => {
  if (newVal) {
    nextTick(() => {
      const neonCursorElement = document.getElementById('neoncursor')
      if (neonCursorElement) {
        neonCursor({
          el: neonCursorElement,
          shaderPoints: 16,
          curvePoints: 80,
          curveLerp: 0.5,
          radius1: 5,
          radius2: 30,
          velocityTreshold: 30,
          sleepRadiusX: 100,
          sleepRadiusY: 100,
          sleepTimeCoefX: 0.0025,
          sleepTimeCoefY: 0.0025
        })
      }
    })
  }
})
</script>

<style>
.neoncursor {
  width: 100vw;
  height: 100vh;
  touch-action: pan-up;
  position: fixed;
  left: 0;
  top: 0;
}
</style>
