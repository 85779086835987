<template>
  <nav :class="[
    'flex flex-col h-full fixed border !border-l-0 !border-t-0 !border-b-0 border-r-1 border-lightMarine',
    isCollapsed ? 'w-[77px]' : 'w-[216px]',
    isDark ? 'bg-black/80' : 'bg-darkMarine',
  ]">
    <!-- Site Title  -->
    <div v-if="!isCollapsed" class="flex justify-center items-center gap-4 pl-8 py-6 pr-4">
      <h2 class="sora-L-strong text-whiteCustom select-none">EduKami.ai</h2>
      <Icon icon="collapse_hor" className="text-lightViolet pb-4 cursor-pointer" @click="emit('toggleCollapse')" />
    </div>

    <div v-else class="flex flex-col justify-center items-center gap-3 pt-6 px-6">
      <h2 class="sora-L-strong text-whiteCustom select-none">EK</h2>
      <Icon icon="uncollapse_hor" className="text-lightViolet pb-4 cursor-pointer" @click="emit('toggleCollapse')" />
    </div>

    <!-- Menu -->
    <div class="flex flex-col gap-8" :class="{ 'p-8': !isCollapsed, 'py-8 px-6': isCollapsed }">
      <NavBarItem v-for="item in menu" :key="item.id" :text="item.text" :path="item.path" :icon="item.icon"
        :active="isActiveRoute(item)" :isCollapsed="isCollapsed" />
    </div>

    <!-- Control Panel -->
    <div class="mt-auto pb-8 flex flex-col gap-4" :class="{ 'px-8 ': !isCollapsed, 'px-6 m-0': isCollapsed }">
      <!-- Change view by user -->
      <div v-if="!isCollapsed && isAdminOrTeacher" class="w-full flex flex-col items-start gap-2">
        <p class="sora-XXS-strong text-whiteCustom">
          Mode
        </p>
        <div class="w-full">
          <Dropdown :options="panelOptions" type="navbar" />
        </div>
      </div>

      <!-- User details -->
      <NavBarUserProfile :isCollapsed="isCollapsed" :user="user" />
    </div>
  </nav>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import NavBarItem from '@/components/app/NavBarItem.vue'
import NavBarUserProfile from '@/components/app/NavBarUserProfile.vue'
import Icon from '@/components/Icon.vue'
import Dropdown from '@/components/forms/Dropdown.vue'

const props = defineProps({
  isCollapsed: Boolean,
  user: { type: Object, required: true },
  menu: { type: Array, required: true },
  panelOptions: { type: Array, default: () => [] },
  isDark: { type: Boolean, default: false }
})

const emit = defineEmits(['toggleCollapse'])
const route = useRoute()
const isAdminOrTeacher = computed(() =>
  props.user.role === 'Admin'
  || props.user.role === 'Teacher'
  || route?.fullPath.includes('admin')
)

const isActiveRoute = (item) => route?.fullPath.includes(item.path)
</script>
