<template>
  <div id="dropdown" class="SPACE-M gap-2 relative" :class="{
    'bg-mediumMarine ': props.type === 'navbar' || props.type === 'primary',
    'bg-transparent': props.type === 'secondary'
  }">
    <!-- First option which is shown by default -->
    <div class="flex justify-between items-center rounded-xs py-[10px] px-3 gap-2 cursor-pointer" :class="{
      'border  border-turquoisePrimary text-turquoisePrimary': props.type === 'navbar' || props.type === 'primary',
      'border   border-whiteCustom text-whiteCustom': props.type === 'secondary'
    }" @click="toggleDropdown">
      <p>{{ selectedOption }}</p>
      <Icon :icon="`${!isOpen ? 'chevron_down' : 'chevron_up'}`" size="w-4 h-4" />
    </div>
    <!-- Options to be chosen -->
    <div v-if="isOpen" class="shadow-custom rounded-xs absolute z-10 max-h-52 overflow-y-auto scrollbar-custom " :class="{
      'border border-turquoisePrimary bg-mediumMarine bottom-full mb-3 ': props.type === 'navbar'
      , 'bg-lightMarine top-full  px-3 mt-3': props.type !== 'navbar',
      'right-0': props.position === 'left'
    }">
      <div v-for="option in props.options" :key="option" :value="option">
        <p class="text-start py-2 px-3 whitespace-nowrap" :class="{
          'text-turquoisePrimary cursor-pointer ': option.name === selectedOption,
          'text-whiteCustom hover:text-turquoisePrimary cursor-pointer': option.name !== selectedOption && !option.disabled,
          'text-whiteCustom/30 cursor-default': option.disabled
        }" @click="selectOption(option)">{{ option.name }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'

import Icon from '@/components/Icon.vue'

const props = defineProps({
  options: {
    type: Array
  },
  type: {
    type: String,
    default: 'primary'
  },
  position: {
    type: String,
    default: 'right'
  },
  selected: String
})

const emit = defineEmits(['click'])

const isOpen = ref(false)
const selectedOption = ref(props.selected || props.options[0].name)

const toggleDropdown = (event) => {
  event.stopPropagation()
  isOpen.value = !isOpen.value
}

const selectOption = (option) => {
  // if option is disabled do nothing
  if (option.disabled) {
    return
  }
  // if option has an action, execute it
  else if (option.action) {
    option.action(option.name)
  }
  emit('click', option)
  // select an option and close the dropdown
  selectedOption.value = option.name
  isOpen.value = false
}

// Close dropdown if clicked outside it
const handleClickOutside = (event) => {
  event.stopPropagation()
  if (!event.target.closest('#dropdown')) {
    isOpen.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
})

// Select again previous status in Admin/Cohort Home
watch(() => props.selected, (newVal) => {
  selectedOption.value = newVal
})
</script>
