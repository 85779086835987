<template>
  <transition enter-active-class="transform ease-in duration-100 transition "
    enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
    enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-out duration-100"
    leave-from-class="opacity-100" leave-to-class="opacity-0">
    <div v-if="showNotification" :class="notificationClass" @click="handleClose">
      <Icon v-if="type == 'error'" icon="alert_negative" size="w-12 h-12" />
      <Icon v-if="type == 'success'" icon="alert_positive" size="w-12 h-12" />
      <div class="flex flex-col gap-[6px] ">
        <p class="sora-M-strong text-mediumMarine">{{ title }}</p>
        <p v-if="message" class="sora-XS text-mediumMarine">{{ message }}</p>
      </div>
      <div class="ml-auto">
        <Icon icon="close" class="text-lightMarine cursor-pointer hover:text-darkMarine " @click="handleClose" />
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref, computed } from 'vue'
import Icon from './Icon.vue'

const emit = defineEmits(['close'])
const showNotification = ref(true)

const props = defineProps({
  id: Number,
  title: String,
  message: String,
  // Types: error, success
  type: {
    type: String,
    default: 'error'
  }
})

const handleClose = () => {
  showNotification.value = false
  emit('close', props.id) // Emit the ID along with the close event
}

const notificationClass = computed(() => {
  const baseClass = 'pointer-events-auto max-w-[500px] cursor-pointer overflow-hidden rounded-lg flex justify-center items-center gap-6 py-4 px-6'

  switch (props.type) {
    case 'error':
      return `${baseClass} bg-lightRed`
    case 'success':
      return `${baseClass} bg-lightTurquoise`
    default:
      return `${baseClass} bg-white`
  }
})

</script>
