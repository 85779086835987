import axios from 'axios'

const CLOUDINARY_URL = 'https://api.cloudinary.com/v1_1/dbjlaop5w/image/upload'

export const uploadPhoto = async (file, folder) => {
    const formData = new FormData()
    const url = {}
    formData.append('file', file)
    formData.append('upload_preset', 'avatar_thumb_medium_png')
    formData.append('folder', folder)
    let response = await axios.post(CLOUDINARY_URL, formData)
    url.small = response.data.secure_url

    formData.set('upload_preset', 'avatar_thumb_medium_png')

    response = await axios.post(CLOUDINARY_URL, formData)
    url.medium = response.data.secure_url

    return url
}

export const uploadSkillPhoto = async (file, folder) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('upload_preset', 'avatar_thumb_medium_png')
    formData.append('folder', folder)

    try {
        const response = await axios.post(CLOUDINARY_URL, formData)
        return {
            medium: response.data.secure_url
        }
    } catch (error) {
        console.error('Error uploading image:', error)
        throw error
    }
}
