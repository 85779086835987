<!-- New Design -->
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-40">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-lightMarine/80" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="transform overflow-hidden transition-all max-w-[575px] min-w-[575px] bg-darkMarine rounded-m"
              :class="dialogClass">
              <div class="w-full flex justify-end px-6 pt-6 pb-2">
                <Icon icon="close" class="text-turquoisePrimary cursor-pointer" @click="closeModal" />
              </div>
              <div class="flex flex-col
               px-14 pb-14">
                <div class="flex flex-col items-center">
                  <p class="text-whiteCustom sora-XL text-center w-full">{{ props.title }}</p>
                  <p class="text-whiteCustom sora-M mb-12 w-[463px] text-center mt-4">{{ props.subtitle }}</p>
                </div>
                <slot></slot>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel
} from '@headlessui/vue'
import Icon from '@/components/Icon.vue'

const emit = defineEmits(['closeModal'])
const props = defineProps({
  title: String,
  subtitle: String,
  isOpen: {
    type: Boolean,
    default: false
  },
  dialogClass: {
    type: String,
    default: ''
  }
})

function closeModal() {
  emit('closeModal')
}

</script>
